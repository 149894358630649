import React from 'react';
import TopNav from './../navigation/TopNav';
import Breadcrumbs from './../navigation/Breadcrumbs';
import Icon from './../utils/Icon'
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl";

function TopHeader({ currentStep, totalSteps }) {
	return (
		<React.Fragment>
			<TopNav>
	            <Link to="/calculator">
	                <Icon icon={"back"} width={20} height={20} />
	            </Link>
	            <h1><FormattedMessage id="topnav.overtime.h1" /></h1>
	        </TopNav>
	        <Breadcrumbs
	            currentStep={currentStep}
	            totalSteps={totalSteps}
	        />
        </React.Fragment>
	)
}

export default TopHeader;