import React from 'react';
import { FormattedMessage } from "react-intl";
import { Card, CardContent } from '../utils/Card';
import PrimaryIncome from './PrimaryIncome';
import TopHeader from '../navigation/TopHeader';
import IncomeList from './IncomeList';

function IncomeStep() {
    return(
        <React.Fragment>
            <TopHeader>
                <h2><FormattedMessage id="topheader.income.h2" /></h2>
                <p><FormattedMessage id="topheader.income.p" /></p>
            </TopHeader>
            <div className="screen-content">
                <div className="container">
                    <PrimaryIncome />
                    <Card>
                        <CardContent>
                            <h4><FormattedMessage id="card.other_income.h4" /></h4>
                            <p><FormattedMessage id="card.other_income.p" /></p>
                        </CardContent>
                    </Card>
                    <IncomeList />
                </div>
            </div>
        </React.Fragment>
    );
}

export default IncomeStep;