import React from 'react'
import { FormattedMessage } from "react-intl"
import TopHeader from './../navigation/TopHeader'
import { Card, CardContent } from './../utils/Card'
import PrimaryIncome from './PrimaryIncome'
import OvertimeList from './OvertimeList'

function OvertimeStep1() {
    return(
        <React.Fragment>
            <TopHeader>
                <h2><FormattedMessage id="topheader.overtime.h2" /></h2>
                <p><FormattedMessage id="topheader.overtime.p" /></p>
            </TopHeader>
            <div className="screen-content">
                <div className="container">
                    <PrimaryIncome />
                    <Card>
                        <CardContent>
                            <h4><FormattedMessage id="label.overtime_hours.h4" /></h4>
                            <p><FormattedMessage id="label.overtime_hours.p" /></p>
                        </CardContent>
                    </Card>
                    <OvertimeList />
                </div>
            </div>
        </React.Fragment>
    );
}

export default OvertimeStep1;