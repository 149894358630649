import React from 'react';
import { FormattedMessage } from "react-intl";

export default function Breadcrumbs({ currentStep, totalSteps }) {
    return (
        <div className="breadcrumbs">
            <div className="container">
                <FormattedMessage id="breadcrumbs.step" values={{step: currentStep, count: totalSteps}}
                />
            </div>
        </div>
    );	    
}