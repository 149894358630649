import React, { useState, useEffect, useContext } from 'react'
import Icon from '../utils/Icon';
import { AppContext } from '../App'
import endpoint from '../../api'

export default function CurrencySelect({ type }) {
	const { handleCurrencyChange, currency } = useContext(AppContext)

    const [currencies, setCurrencies] = useState([])

    useEffect(() => {
        endpoint.get(`/currencies/`).then(response => {
            setCurrencies(response.data)
        })
    }, [])

    useEffect(() => {
        if (currencies.length > 0 && !currency) {
            handleCurrencyChange(currencies.find(() => true))
        }        
    }, [currencies, currency, handleCurrencyChange])

    function handleSelectOnChange(newCurrency) {
        handleCurrencyChange(newCurrency)
    }

	return (
		<div className={`select-control ${type}`}>
            <select
                name={"currency"}
                value={currency}
                onChange={(e) => handleSelectOnChange(e.target.value)}
            >
                {currencies.map(currency => <option key={currency} value={currency}>{currency}</option>)}
            </select>
            <span className="text">
                {currency}
            </span>
            <Icon icon={"arrow_down"} />
        </div>
	)
}
