import React, { useState, useEffect, useContext } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { BudgetContext } from "./index";
import SummaryPieChart from "./SummaryPieChart";
import Summary from "./Summary";
import SummaryTotal from "./SummaryTotal";
import { Card, CardContent } from "../utils/Card";
import Icon from "../utils/Icon";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function SummaryList() {
  const { incomes, expenses, primaryIncome, handlePrintDocument } =
    useContext(BudgetContext);

  const [payPeriod, setPayPeriod] = useState(primaryIncome.pay_period);

  const [totalIncomes, setTotalIncomes] = useState(() => {
    return {
      label: "label.total_income",
      color: "",
      amount: getTotalByPayPeriod(incomes),
    };
  });

  const [totalExpenses, setTotalExpenses] = useState(() => {
    let amount = getTotalByPayPeriod(expenses);
    let color = "#00AB00";

    return {
      label: "label.total_expense",
      color: color,
      amount: amount,
    };
  });

  const [totalAmount, setTotalAmount] = useState(() => {
    let amount = parseFloat(totalIncomes.amount - totalExpenses.amount);
    let color = "#CC0000";

    if (Math.sign(amount) == 1) {
      color = "#00AB00";
      let changes = { color: "#CC0000" };
      setTotalExpenses({ ...totalExpenses, ...changes });
    }

    return {
      label: "label.money_left",
      color: color,
      amount: amount,
    };
  });

  useEffect(() => {
    setTotalIncomes({ ...totalIncomes, amount: getTotalByPayPeriod(incomes) });
    setTotalExpenses({
      ...totalExpenses,
      amount: getTotalByPayPeriod(expenses),
    });
    setTotalAmount({
      ...totalAmount,
      amount: parseFloat(
        getTotalByPayPeriod(incomes) - getTotalByPayPeriod(expenses)
      ),
    });
  }, [payPeriod]);

  function getTotalByPayPeriod(items) {
    var total = 0;

    items.map((item, index) => {
      total += parseFloat(
        (isNaN(parseFloat(item.amount)) ? 0 : parseFloat(item.amount)) /
          item.pay_period
      );
    });

    return parseFloat(total * payPeriod);
  }

  function handlePayPeriod(newPayPeriod) {
    setPayPeriod(newPayPeriod);
  }

  function printDocument() {
    const input = document.getElementById("divToPrint");
    const options = {
      scrollX: 0,
      scrollY: 0,
      allowTaint: true,
      useCORS: true,
      width: 793,
      height: 1122,
      windowHeight: window.outerHeight + window.innerHeight,
    };
    html2canvas(input, options).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpg");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "JPEG", 0, 0, 210, 297);
      pdf.save("budget.pdf");
    });
  }

  return (
    <div>
      <div id="divToPrint">
        {totalAmount.amount < 0 && (
          <div className="label label--yellow economic-hardship">
            <Icon icon={"warning"} />
            <FormattedMessage id={"economic.hardship"} />
          </div>
        )}
        <Card className={"card--white"}>
          <SummaryPieChart
            totalExpenses={totalExpenses}
            totalAmount={totalAmount}
          />
          <CardContent>
            <div className="summary-table">
              <Summary {...totalIncomes} />
              <Summary {...totalExpenses} />
              <SummaryTotal
                handlePayPeriod={handlePayPeriod}
                payPeriod={payPeriod}
                totalAmount={totalAmount}
              />
            </div>
          </CardContent>
        </Card>
      </div>
      <button
        class="btn btn--primary download-summary"
        onClick={() => printDocument()}
      >
        <FormattedMessage id={"label.download_summary"} />
      </button>
    </div>
  );
}

export default SummaryList;
