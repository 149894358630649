export default {
  EN: {
    "topheader.compare.help": "Need help? Check out our video guides.",
    "carousel.item1.h2": "Save Time",
    "carousel.item2.h2": "Save Money",
    "carousel.item3.h2": "Save for your Future",
    "carousel.item1.p":
      "Save time by quickly comparing money transfer providers – to help you find the best price and product when you send money home",
    "carousel.item2.p":
      "Learn how to save money by comparing money transfer providers and using the SaverAsia calculators",
    "carousel.item3.p":
      "Learn how to save for your future and improve your finances through our finance basics guide",
    "button.start": "Start",
    "topnav.compare.form.h1": "Compare Rates",
    "topheader.compare.form.h2": "Save on money transfers",
    "topheader.compare.form.p":
      "Compare costs of companies offering money transfer services",
    "label.country_from": "Send money from",
    "label.country_to": "Send to",
    "label.how_much": "How much",
    "button.compare": "Compare rates now",
    "topheader.compare.list.h2": "Comparison Results",
    "topheader.compare.list.green": "Results live as of today, {date_today}. ",
    "topheader.compare.list.amber": "Results collected on {date_updated}. ",
    "topheader.compare.list.red":
      "Results collected via mystery shopping on {date_updated}. ",
    "topheader.compare.list.p":
      "Send money from {country_from} to {country_to} with SaverAsia",
    "bottomheader.compare.list.p":
      "These results may not include all services available for this remittance corridor. The above information includes all known costs.",
    "topnav.resource.h1": "Finance Basics",
    "topheader.resource.form.h2": "Finance Basics",
    "topheader.resource.form.p":
      "To help you save or send your money, please read our Finance Basics tips.",
    "topnav.calculators.h1": "Calculators",
    "card1.calculators.h3": "Overtime Pay Calculator",
    "card1.calculators.p":
      "If you are working in Malaysia you can use this calculator to work out how much overtime pay you should receive.",
    "card2.calculators.h3": "Budget Calculator",
    "card2.calculators.p":
      "Add your income and your expenses to see how much you could save and to make sure you aren’t overspending.",
    "breadcrumbs.step": "Step {step} of {count}",
    "topnav.overtime.h1": "Overtime Calculator",
    "topheader.overtime.h2": "Malaysian Overtime Pay Calculator",
    "topheader.overtime.p":
      "This calculator will work out the amount of overtime you should receive based on the hours you have worked and the standard government over time requirements.",
    "label.hourly_rate": "Your hourly rate is ",
    "label.overtime_hours.h4": "Overtime Hours",
    "label.overtime_hours.p":
      "As per Employment Act 1955 (Malaysia), the employee shall be paid at a rate not less than 1.5 times his/her hourly rate for overtime work in excess of the normal work hours. For work on a rest day the pay shall be no less than 2.0 times the hourly rate, and on a public holiday no less than 3.0 times the hourly rate.",
    "label.basic_wage": "Basic wage",
    "label.pay_period": "Pay period",
    "label.amount": "Amount",
    "label.standard_days": "Normal Days (x1.5)",
    "label.rest_days": "Rest Days (x2)",
    "label.public_holidays": "Public Holidays (x3)",
    "label.paid_public_holidays": "Paid Public Holidays (x2)",
    "label.unpaid_public_holidays": "Unpaid Public Holidays (x3)",
    "label.overtime": "Overtime",
    "label.total_expense": "Total Expenses",
    "label.total_income": "Total Income",
    "label.money_left": "Money left over",
    "label.daily": "Daily",
    "label.weekly": "Weekly",
    "label.monthly": "Monthly",
    "label.item": "Item",
    "label.primary_income": "Primary Income",
    "label.market_rate":
      "The mid market FX is {market_rate} for live data results",
    "label.operator": "Operator",
    "label.type": "Form of transfer",
    "label.receiving": "Receiving",
    "label.rate": "FX rate",
    "label.fee": "Fee",
    "label.form": "Form",
    "label.method": "Method of transfer",
    "label.speed": "Speed of transfer",
    "label.number_overtime": "Number of hours worked overtime",
    "topnav.faq.h1": "FAQ",
    "topnav.video.h1": "Guides",
    "topheader.video.form.h2": "Video Guides",
    "topheader.video.form.p":
      "Please use our video guides to help you understand how to use the SaverAsia App functions.",
    "bottom.faq.p":
      "SaverAsia has the answers to most of your questions but if you need more information, please contact us at <a>enquiries@saver.global</a> and we will try to help you.",
    "notfound.h2": '"Oh - Uh"',
    "notfound.p":
      "Sorry, we can't find the screen you are looking for. Use the navigation below.",
    "topheader.summary.h2": "Summary",
    "topheader.summary.p":
      "Below you can see how much money you should receive for this period.",
    "label.no_results":
      "No results containing all your filter terms were found.",
    "button.back": "Back",
    "button.prev": "Prev",
    "button.next": "Next",
    "button.remove": "Remove",
    "button.add_income": "Add income",
    "button.add_expense": "Add expense",
    "button.read_more": "Read more",
    "button.show_less": "Show less",
    "topnav.budget.h1": "Budget Calculator",
    "topheader.income.h2": "Income",
    "topheader.income.p":
      "Let's start with entering all the ways you earn money.",
    "topheader.expense.h2": "Expenses",
    "topheader.expense.p":
      "Add your expenses below. E.g. rent, food, remittances, bills (phone, electricity etc.) and travel.",
    "topheader.budget_summary.p":
      "Well done on completing your budget plan, let’s see how much money you’ve saved.",
    "card.other_income.h4": "Other Income",
    "card.other_income.p":
      "Do you earn any other income? E.g. government benefits, second job, rental income, driving a Grab, shares, etc.",
    "topnav.setting.h1": "Settings",
    "label.language": "Language",
    "label.currency": "Currency",
    "label.push_notification": "Push Notifications",
    "label.language.desc": "Select the app language",
    "label.currency.desc": "Select the app currency",
    "label.push_notification.desc": "Allow app to push notification",
    "label.not_supported": "Not supported",
    "button.view_all_operators": "View all operators",
    "logo.supported.h3": "Supported by",
    "logo.managed.h3": "Managed by",
    "label.view_legend": "View legend",
    "label.legend": "Legend",
    "menu.about": "About",
    "menu.privacy_policy": "Privacy Policy",
    "menu.terms_and_conditions": "Terms and Conditions",
    "menu.disclaimer": "Disclaimer",
    "label.january": "January",
    "label.february": "‌February",
    "label.march": "March",
    "label.april": "April",
    "label.may": "‌May",
    "label.june": "June",
    "label.july": "July",
    "label.august": "August",
    "label.september": "September",
    "label.october": "‌October",
    "label.november": "November",
    "label.december": "December",
    "error.primary_income": "The primary income is required",
    "error.basic_wage": "The basic wage is required",
    "button.apply": "Apply",
    "button.clear": "Clear",
    "economic.hardship":
      "Possible economic hardship may occur if you continue to spend at this rate.",
    "play.video": "Play Video",
    "label.download": "Download",
    "label.download_summary": "Download Summary",
    "label.phone": "Phone",
    "label.fax": "Fax",
    "label.email": "Email",
    "label.website": "Website",
    "button.send_money": "Send Money",
    "label.fx": "FX",
    "label.more_options_available": "More transfer options available",
    "label.send_method": "Send Method",
    "label.receive_method": "Receive Method",
    "button.more_details": "More Details",
    "label.no_additional_fees": "No additional fees",
    "label.additional_fee": "* Receiving fees may apply",
    "onesignal.promptOptions.actionMessage":
      "We'd like to send you push notifications. You can unsubscribe at any time.",
    "onesignal.promptOptions.exampleNotificationTitleDesktop":
      "SaverAsia notification",
    "onesignal.promptOptions.exampleNotificationMessageDesktop":
      "Notifications will appear on your desktop",
    "onesignal.promptOptions.exampleNotificationTitleMobile":
      "SaverAsia notification",
    "onesignal.promptOptions.exampleNotificationMessageMobile":
      "Notifications will appear on your device",
    "onesignal.promptOptions.exampleNotificationCaption":
      "(you can unsubscribe anytime)",
    "onesignal.promptOptions.acceptButton": "ALLOW",
    "onesignal.promptOptions.acceptButtonText": "ALLOW",
    "onesignal.promptOptions.cancelButton": "NO THANKS",
    "onesignal.promptOptions.cancelButtonText": "NO THANKS",
    "onesignal.welcomeNotification.title": "Welcome to SaverAsia",
    "onesignal.welcomeNotification.message": "Thanks for subscribing!",
    "onesignal.notifyButton.text.tipStateUnsubscribed":
      "Subscribe to notifications",
    "onesignal.notifyButton.text.tipStateSubscribed":
      "You're subscribed to notifications",
    "onesignal.notifyButton.text.tipStateBlocked":
      "You've blocked notifications",
    "onesignal.notifyButton.text.messageActionSubscribed":
      "Thanks for subscribing!",
    "onesignal.notifyButton.text.messageActionUnsubscribed":
      "You won't receive notifications again",
    "onesignal.notifyButton.text.dialogMainTitle": "Manage Site Notifications",
    "onesignal.notifyButton.text.dialogMainButtonSubscribe": "SUBSCRIBE",
    "onesignal.notifyButton.text.dialogMainButtonUnsubscribe": "UNSUBSCRIBE",
    "onesignal.notifyButton.text.dialogBlockedTitle": "Unblock Notifications",
    "onesignal.notifyButton.text.dialogBlockedMessage":
      "Follow these instructions to allow notifications:",
  },
  AU: {
    "topheader.compare.help": "Need help? Check out our video guides.",
    "carousel.item1.h2": "Save Time",
    "carousel.item2.h2": "Save Money",
    "carousel.item3.h2": "Save for your Future",
    "carousel.item1.p":
      "Save time by quickly comparing money transfer providers – to help you find the best price and product when you send money home",
    "carousel.item2.p":
      "Learn how to save money by comparing money transfer providers and using the SaverAsia calculators",
    "carousel.item3.p":
      "Learn how to save for your future and improve your finances through our finance basics guide",
    "button.start": "Start",
    "topnav.compare.form.h1": "Compare Rates",
    "topheader.compare.form.h2": "Save on money transfers",
    "topheader.compare.form.p":
      "Compare costs of companies offering money transfer services",
    "label.country_from": "Send money from",
    "label.country_to": "Send to",
    "label.how_much": "How much",
    "button.compare": "Compare rates now",
    "topheader.compare.list.h2": "Comparison Results",
    "topheader.compare.list.green": "Results live as of today, {date_today}. ",
    "topheader.compare.list.amber": "Results collected on {date_updated}. ",
    "topheader.compare.list.red":
      "Results collected via mystery shopping on {date_updated}. ",
    "topheader.compare.list.p":
      "Send money from {country_from} to {country_to} with SaverAsia",
    "bottomheader.compare.list.p":
      "These results may not include all services available for this remittance corridor. The above information includes all known costs.",
    "topnav.resource.h1": "Finance Basics",
    "topheader.resource.form.h2": "Finance Basics",
    "topheader.resource.form.p":
      "To help you save or send your money, please read our Finance Basics tips.",
    "topnav.calculators.h1": "Calculators",
    "card1.calculators.h3": "Overtime Pay Calculator",
    "card1.calculators.p":
      "If you are working in Malaysia you can use this calculator to work out how much overtime pay you should receive.",
    "card2.calculators.h3": "Budget Calculator",
    "card2.calculators.p":
      "Add your income and your expenses to see how much you could save and to make sure you aren’t overspending.",
    "breadcrumbs.step": "Step {step} of {count}",
    "topnav.overtime.h1": "Overtime Calculator",
    "topheader.overtime.h2": "Malaysian Overtime Pay Calculator",
    "topheader.overtime.p":
      "This calculator will work out the amount of overtime you should receive based on the hours you have worked and the standard government over time requirements.",
    "label.hourly_rate": "Your hourly rate is ",
    "label.overtime_hours.h4": "Overtime Hours",
    "label.overtime_hours.p":
      "As per Employment Act 1955 (Malaysia), the employee shall be paid at a rate not less than 1.5 times his/her hourly rate for overtime work in excess of the normal work hours. For work on a rest day the pay shall be no less than 2.0 times the hourly rate, and on a public holiday no less than 3.0 times the hourly rate.",
    "label.basic_wage": "Basic wage",
    "label.pay_period": "Pay period",
    "label.amount": "Amount",
    "label.standard_days": "Normal Days (x1.5)",
    "label.rest_days": "Rest Days (x2)",
    "label.public_holidays": "Public Holidays (x3)",
    "label.paid_public_holidays": "Paid Public Holidays (x2)",
    "label.unpaid_public_holidays": "Unpaid Public Holidays (x3)",
    "label.overtime": "Overtime",
    "label.total_expense": "Total Expenses",
    "label.total_income": "Total Income",
    "label.money_left": "Money left over",
    "label.daily": "Daily",
    "label.weekly": "Weekly",
    "label.monthly": "Monthly",
    "label.item": "Item",
    "label.primary_income": "Primary Income",
    "label.market_rate":
      "The mid market FX is {market_rate} for live data results",
    "label.operator": "Operator",
    "label.type": "Form of transfer",
    "label.receiving": "Receiving",
    "label.rate": "FX rate",
    "label.fee": "Fee",
    "label.form": "Form",
    "label.method": "Method of transfer",
    "label.speed": "Speed of transfer",
    "label.number_overtime": "Number of hours worked overtime",
    "topnav.faq.h1": "FAQ",
    "topnav.video.h1": "Guides",
    "topheader.video.form.h2": "Video Guides",
    "topheader.video.form.p":
      "Please use our video guides to help you understand how to use the SaverAsia App functions.",
    "bottom.faq.p":
      "SaverAsia has the answers to most of your questions but if you need more information, please contact us at <a>enquiries@saver.global</a> and we will try to help you.",
    "notfound.h2": '"Oh - Uh"',
    "notfound.p":
      "Sorry, we can't find the screen you are looking for. Use the navigation below.",
    "topheader.summary.h2": "Summary",
    "topheader.summary.p":
      "Below you can see how much money you should receive for this period.",
    "label.no_results":
      "No results containing all your filter terms were found.",
    "button.back": "Back",
    "button.prev": "Prev",
    "button.next": "Next",
    "button.remove": "Remove",
    "button.add_income": "Add income",
    "button.add_expense": "Add expense",
    "button.read_more": "Read more",
    "button.show_less": "Show less",
    "topnav.budget.h1": "Budget Calculator",
    "topheader.income.h2": "Income",
    "topheader.income.p":
      "Let's start with entering all the ways you earn money.",
    "topheader.expense.h2": "Expenses",
    "topheader.expense.p":
      "Add your expenses below. E.g. rent, food, remittances, bills (phone, electricity etc.) and travel.",
    "topheader.budget_summary.p":
      "Well done on completing your budget plan, let’s see how much money you’ve saved.",
    "card.other_income.h4": "Other Income",
    "card.other_income.p":
      "Do you earn any other income? E.g. government benefits, second job, rental income, driving a Grab, shares, etc.",
    "topnav.setting.h1": "Settings",
    "label.language": "Language",
    "label.currency": "Currency",
    "label.push_notification": "Push Notifications",
    "label.language.desc": "Select the app language",
    "label.currency.desc": "Select the app currency",
    "label.push_notification.desc": "Allow app to push notification",
    "label.not_supported": "Not supported",
    "button.view_all_operators": "View all operators",
    "logo.supported.h3": "Supported by",
    "logo.managed.h3": "Managed by",
    "label.view_legend": "View legend",
    "label.legend": "Legend",
    "menu.about": "About",
    "menu.privacy_policy": "Privacy Policy",
    "menu.terms_and_conditions": "Terms and Conditions",
    "menu.disclaimer": "Disclaimer",
    "label.january": "January",
    "label.february": "‌February",
    "label.march": "March",
    "label.april": "April",
    "label.may": "‌May",
    "label.june": "June",
    "label.july": "July",
    "label.august": "August",
    "label.september": "September",
    "label.october": "‌October",
    "label.november": "November",
    "label.december": "December",
    "error.primary_income": "The primary income is required",
    "error.basic_wage": "The basic wage is required",
    "button.apply": "Apply",
    "button.clear": "Clear",
    "economic.hardship":
      "Possible economic hardship may occur if you continue to spend at this rate.",
    "play.video": "Play Video",
    "label.download": "Download",
    "label.download_summary": "Download Summary",
    "label.phone": "Phone",
    "label.fax": "Fax",
    "label.email": "Email",
    "label.website": "Website",
    "button.send_money": "Send Money",
    "label.fx": "FX",
    "label.more_options_available": "More transfer options available",
    "label.send_method": "Send Method",
    "label.receive_method": "Receive Method",
    "button.more_details": "More Details",
    "label.no_additional_fees": "No additional fees",
    "label.additional_fee": "* Receiving fees may apply",
    "onesignal.promptOptions.actionMessage":
      "We'd like to send you push notifications. You can unsubscribe at any time.",
    "onesignal.promptOptions.exampleNotificationTitleDesktop":
      "SaverAsia notification",
    "onesignal.promptOptions.exampleNotificationMessageDesktop":
      "Notifications will appear on your desktop",
    "onesignal.promptOptions.exampleNotificationTitleMobile":
      "SaverAsia notification",
    "onesignal.promptOptions.exampleNotificationMessageMobile":
      "Notifications will appear on your device",
    "onesignal.promptOptions.exampleNotificationCaption":
      "(you can unsubscribe anytime)",
    "onesignal.promptOptions.acceptButton": "ALLOW",
    "onesignal.promptOptions.acceptButtonText": "ALLOW",
    "onesignal.promptOptions.cancelButton": "NO THANKS",
    "onesignal.promptOptions.cancelButtonText": "NO THANKS",
    "onesignal.welcomeNotification.title": "Welcome to SaverAsia",
    "onesignal.welcomeNotification.message": "Thanks for subscribing!",
    "onesignal.notifyButton.text.tipStateUnsubscribed":
      "Subscribe to notifications",
    "onesignal.notifyButton.text.tipStateSubscribed":
      "You're subscribed to notifications",
    "onesignal.notifyButton.text.tipStateBlocked":
      "You've blocked notifications",
    "onesignal.notifyButton.text.messageActionSubscribed":
      "Thanks for subscribing!",
    "onesignal.notifyButton.text.messageActionUnsubscribed":
      "You won't receive notifications again",
    "onesignal.notifyButton.text.dialogMainTitle": "Manage Site Notifications",
    "onesignal.notifyButton.text.dialogMainButtonSubscribe": "SUBSCRIBE",
    "onesignal.notifyButton.text.dialogMainButtonUnsubscribe": "UNSUBSCRIBE",
    "onesignal.notifyButton.text.dialogBlockedTitle": "Unblock Notifications",
    "onesignal.notifyButton.text.dialogBlockedMessage":
      "Follow these instructions to allow notifications:",
  },
  NZ: {
    "topheader.compare.help": "Need help? Check out our video guides.",
    "carousel.item1.h2": "Save Time",
    "carousel.item2.h2": "Save Money",
    "carousel.item3.h2": "Save for your Future",
    "carousel.item1.p":
      "Save time by quickly comparing money transfer providers – to help you find the best price and product when you send money home",
    "carousel.item2.p":
      "Learn how to save money by comparing money transfer providers and using the SaverAsia calculators",
    "carousel.item3.p":
      "Learn how to save for your future and improve your finances through our finance basics guide",
    "button.start": "Start",
    "topnav.compare.form.h1": "Compare Rates",
    "topheader.compare.form.h2": "Save on money transfers",
    "topheader.compare.form.p":
      "Compare costs of companies offering money transfer services",
    "label.country_from": "Send money from",
    "label.country_to": "Send to",
    "label.how_much": "How much",
    "button.compare": "Compare rates now",
    "topheader.compare.list.h2": "Comparison Results",
    "topheader.compare.list.green": "Results live as of today, {date_today}. ",
    "topheader.compare.list.amber": "Results collected on {date_updated}. ",
    "topheader.compare.list.red":
      "Results collected via mystery shopping on {date_updated}. ",
    "topheader.compare.list.p":
      "Send money from {country_from} to {country_to} with SaverAsia",
    "bottomheader.compare.list.p":
      "These results may not include all services available for this remittance corridor. The above information includes all known costs.",
    "topnav.resource.h1": "Finance Basics",
    "topheader.resource.form.h2": "Finance Basics",
    "topheader.resource.form.p":
      "To help you save or send your money, please read our Finance Basics tips.",
    "topnav.calculators.h1": "Calculators",
    "card1.calculators.h3": "Overtime Pay Calculator",
    "card1.calculators.p":
      "If you are working in Malaysia you can use this calculator to work out how much overtime pay you should receive.",
    "card2.calculators.h3": "Budget Calculator",
    "card2.calculators.p":
      "Add your income and your expenses to see how much you could save and to make sure you aren’t overspending.",
    "breadcrumbs.step": "Step {step} of {count}",
    "topnav.overtime.h1": "Overtime Calculator",
    "topheader.overtime.h2": "Malaysian Overtime Pay Calculator",
    "topheader.overtime.p":
      "This calculator will work out the amount of overtime you should receive based on the hours you have worked and the standard government over time requirements.",
    "label.hourly_rate": "Your hourly rate is ",
    "label.overtime_hours.h4": "Overtime Hours",
    "label.overtime_hours.p":
      "As per Employment Act 1955 (Malaysia), the employee shall be paid at a rate not less than 1.5 times his/her hourly rate for overtime work in excess of the normal work hours. For work on a rest day the pay shall be no less than 2.0 times the hourly rate, and on a public holiday no less than 3.0 times the hourly rate.",
    "label.basic_wage": "Basic wage",
    "label.pay_period": "Pay period",
    "label.amount": "Amount",
    "label.standard_days": "Normal Days (x1.5)",
    "label.rest_days": "Rest Days (x2)",
    "label.public_holidays": "Public Holidays (x3)",
    "label.paid_public_holidays": "Paid Public Holidays (x2)",
    "label.unpaid_public_holidays": "Unpaid Public Holidays (x3)",
    "label.overtime": "Overtime",
    "label.total_expense": "Total Expenses",
    "label.total_income": "Total Income",
    "label.money_left": "Money left over",
    "label.daily": "Daily",
    "label.weekly": "Weekly",
    "label.monthly": "Monthly",
    "label.item": "Item",
    "label.primary_income": "Primary Income",
    "label.market_rate":
      "The mid market FX is {market_rate} for live data results",
    "label.operator": "Operator",
    "label.type": "Form of transfer",
    "label.receiving": "Receiving",
    "label.rate": "FX rate",
    "label.fee": "Fee",
    "label.form": "Form",
    "label.method": "Method of transfer",
    "label.speed": "Speed of transfer",
    "label.number_overtime": "Number of hours worked overtime",
    "topnav.faq.h1": "FAQ",
    "topnav.video.h1": "Guides",
    "topheader.video.form.h2": "Video Guides",
    "topheader.video.form.p":
      "Please use our video guides to help you understand how to use the SaverAsia App functions.",
    "bottom.faq.p":
      "SaverAsia has the answers to most of your questions but if you need more information, please contact us at <a>enquiries@saver.global</a> and we will try to help you.",
    "notfound.h2": '"Oh - Uh"',
    "notfound.p":
      "Sorry, we can't find the screen you are looking for. Use the navigation below.",
    "topheader.summary.h2": "Summary",
    "topheader.summary.p":
      "Below you can see how much money you should receive for this period.",
    "label.no_results":
      "No results containing all your filter terms were found.",
    "button.back": "Back",
    "button.prev": "Prev",
    "button.next": "Next",
    "button.remove": "Remove",
    "button.add_income": "Add income",
    "button.add_expense": "Add expense",
    "button.read_more": "Read more",
    "button.show_less": "Show less",
    "topnav.budget.h1": "Budget Calculator",
    "topheader.income.h2": "Income",
    "topheader.income.p":
      "Let's start with entering all the ways you earn money.",
    "topheader.expense.h2": "Expenses",
    "topheader.expense.p":
      "Add your expenses below. E.g. rent, food, remittances, bills (phone, electricity etc.) and travel.",
    "topheader.budget_summary.p":
      "Well done on completing your budget plan, let’s see how much money you’ve saved.",
    "card.other_income.h4": "Other Income",
    "card.other_income.p":
      "Do you earn any other income? E.g. government benefits, second job, rental income, driving a Grab, shares, etc.",
    "topnav.setting.h1": "Settings",
    "label.language": "Language",
    "label.currency": "Currency",
    "label.push_notification": "Push Notifications",
    "label.language.desc": "Select the app language",
    "label.currency.desc": "Select the app currency",
    "label.push_notification.desc": "Allow app to push notification",
    "label.not_supported": "Not supported",
    "button.view_all_operators": "View all operators",
    "logo.supported.h3": "Supported by",
    "logo.managed.h3": "Managed by",
    "label.view_legend": "View legend",
    "label.legend": "Legend",
    "menu.about": "About",
    "menu.privacy_policy": "Privacy Policy",
    "menu.terms_and_conditions": "Terms and Conditions",
    "menu.disclaimer": "Disclaimer",
    "label.january": "January",
    "label.february": "‌February",
    "label.march": "March",
    "label.april": "April",
    "label.may": "‌May",
    "label.june": "June",
    "label.july": "July",
    "label.august": "August",
    "label.september": "September",
    "label.october": "‌October",
    "label.november": "November",
    "label.december": "December",
    "error.primary_income": "The primary income is required",
    "error.basic_wage": "The basic wage is required",
    "button.apply": "Apply",
    "button.clear": "Clear",
    "economic.hardship":
      "Possible economic hardship may occur if you continue to spend at this rate.",
    "play.video": "Play Video",
    "label.download": "Download",
    "label.download_summary": "Download Summary",
    "label.phone": "Phone",
    "label.fax": "Fax",
    "label.email": "Email",
    "label.website": "Website",
    "button.send_money": "Send Money",
    "label.fx": "FX",
    "label.more_options_available": "More transfer options available",
    "label.send_method": "Send Method",
    "label.receive_method": "Receive Method",
    "button.more_details": "More Details",
    "label.no_additional_fees": "No additional fees",
    "label.additional_fee": "* Receiving fees may apply",
    "onesignal.promptOptions.actionMessage":
      "We'd like to send you push notifications. You can unsubscribe at any time.",
    "onesignal.promptOptions.exampleNotificationTitleDesktop":
      "SaverAsia notification",
    "onesignal.promptOptions.exampleNotificationMessageDesktop":
      "Notifications will appear on your desktop",
    "onesignal.promptOptions.exampleNotificationTitleMobile":
      "SaverAsia notification",
    "onesignal.promptOptions.exampleNotificationMessageMobile":
      "Notifications will appear on your device",
    "onesignal.promptOptions.exampleNotificationCaption":
      "(you can unsubscribe anytime)",
    "onesignal.promptOptions.acceptButton": "ALLOW",
    "onesignal.promptOptions.acceptButtonText": "ALLOW",
    "onesignal.promptOptions.cancelButton": "NO THANKS",
    "onesignal.promptOptions.cancelButtonText": "NO THANKS",
    "onesignal.welcomeNotification.title": "Welcome to SaverAsia",
    "onesignal.welcomeNotification.message": "Thanks for subscribing!",
    "onesignal.notifyButton.text.tipStateUnsubscribed":
      "Subscribe to notifications",
    "onesignal.notifyButton.text.tipStateSubscribed":
      "You're subscribed to notifications",
    "onesignal.notifyButton.text.tipStateBlocked":
      "You've blocked notifications",
    "onesignal.notifyButton.text.messageActionSubscribed":
      "Thanks for subscribing!",
    "onesignal.notifyButton.text.messageActionUnsubscribed":
      "You won't receive notifications again",
    "onesignal.notifyButton.text.dialogMainTitle": "Manage Site Notifications",
    "onesignal.notifyButton.text.dialogMainButtonSubscribe": "SUBSCRIBE",
    "onesignal.notifyButton.text.dialogMainButtonUnsubscribe": "UNSUBSCRIBE",
    "onesignal.notifyButton.text.dialogBlockedTitle": "Unblock Notifications",
    "onesignal.notifyButton.text.dialogBlockedMessage":
      "Follow these instructions to allow notifications:",
  },
  UK: {
    "topheader.compare.help": "Need help? Check out our video guides.",
    "carousel.item1.h2": "Save Time",
    "carousel.item2.h2": "Save Money",
    "carousel.item3.h2": "Save for your Future",
    "carousel.item1.p":
      "Save time by quickly comparing money transfer providers – to help you find the best price and product when you send money home",
    "carousel.item2.p":
      "Learn how to save money by comparing money transfer providers and using the SaverAsia calculators",
    "carousel.item3.p":
      "Learn how to save for your future and improve your finances through our finance basics guide",
    "button.start": "Start",
    "topnav.compare.form.h1": "Compare Rates",
    "topheader.compare.form.h2": "Save on money transfers",
    "topheader.compare.form.p":
      "Compare costs of companies offering money transfer services",
    "label.country_from": "Send money from",
    "label.country_to": "Send to",
    "label.how_much": "How much",
    "button.compare": "Compare rates now",
    "topheader.compare.list.h2": "Comparison Results",
    "topheader.compare.list.green": "Results live as of today, {date_today}. ",
    "topheader.compare.list.amber": "Results collected on {date_updated}. ",
    "topheader.compare.list.red":
      "Results collected via mystery shopping on {date_updated}. ",
    "topheader.compare.list.p":
      "Send money from {country_from} to {country_to} with SaverAsia",
    "bottomheader.compare.list.p":
      "These results may not include all services available for this remittance corridor. The above information includes all known costs.",
    "topnav.resource.h1": "Finance Basics",
    "topheader.resource.form.h2": "Finance Basics",
    "topheader.resource.form.p":
      "To help you save or send your money, please read our Finance Basics tips.",
    "topnav.calculators.h1": "Calculators",
    "card1.calculators.h3": "Overtime Pay Calculator",
    "card1.calculators.p":
      "If you are working in Malaysia you can use this calculator to work out how much overtime pay you should receive.",
    "card2.calculators.h3": "Budget Calculator",
    "card2.calculators.p":
      "Add your income and your expenses to see how much you could save and to make sure you aren’t overspending.",
    "breadcrumbs.step": "Step {step} of {count}",
    "topnav.overtime.h1": "Overtime Calculator",
    "topheader.overtime.h2": "Malaysian Overtime Pay Calculator",
    "topheader.overtime.p":
      "This calculator will work out the amount of overtime you should receive based on the hours you have worked and the standard government over time requirements.",
    "label.hourly_rate": "Your hourly rate is ",
    "label.overtime_hours.h4": "Overtime Hours",
    "label.overtime_hours.p":
      "As per Employment Act 1955 (Malaysia), the employee shall be paid at a rate not less than 1.5 times his/her hourly rate for overtime work in excess of the normal work hours. For work on a rest day the pay shall be no less than 2.0 times the hourly rate, and on a public holiday no less than 3.0 times the hourly rate.",
    "label.basic_wage": "Basic wage",
    "label.pay_period": "Pay period",
    "label.amount": "Amount",
    "label.standard_days": "Normal Days (x1.5)",
    "label.rest_days": "Rest Days (x2)",
    "label.public_holidays": "Public Holidays (x3)",
    "label.paid_public_holidays": "Paid Public Holidays (x2)",
    "label.unpaid_public_holidays": "Unpaid Public Holidays (x3)",
    "label.overtime": "Overtime",
    "label.total_expense": "Total Expenses",
    "label.total_income": "Total Income",
    "label.money_left": "Money left over",
    "label.daily": "Daily",
    "label.weekly": "Weekly",
    "label.monthly": "Monthly",
    "label.item": "Item",
    "label.primary_income": "Primary Income",
    "label.market_rate":
      "The mid market FX is {market_rate} for live data results",
    "label.operator": "Operator",
    "label.type": "Form of transfer",
    "label.receiving": "Receiving",
    "label.rate": "FX rate",
    "label.fee": "Fee",
    "label.form": "Form",
    "label.method": "Method of transfer",
    "label.speed": "Speed of transfer",
    "label.number_overtime": "Number of hours worked overtime",
    "topnav.faq.h1": "FAQ",
    "topnav.video.h1": "Guides",
    "topheader.video.form.h2": "Video Guides",
    "topheader.video.form.p":
      "Please use our video guides to help you understand how to use the SaverAsia App functions.",
    "bottom.faq.p":
      "SaverAsia has the answers to most of your questions but if you need more information, please contact us at <a>enquiries@saver.global</a> and we will try to help you.",
    "notfound.h2": '"Oh - Uh"',
    "notfound.p":
      "Sorry, we can't find the screen you are looking for. Use the navigation below.",
    "topheader.summary.h2": "Summary",
    "topheader.summary.p":
      "Below you can see how much money you should receive for this period.",
    "label.no_results":
      "No results containing all your filter terms were found.",
    "button.back": "Back",
    "button.prev": "Prev",
    "button.next": "Next",
    "button.remove": "Remove",
    "button.add_income": "Add income",
    "button.add_expense": "Add expense",
    "button.read_more": "Read more",
    "button.show_less": "Show less",
    "topnav.budget.h1": "Budget Calculator",
    "topheader.income.h2": "Income",
    "topheader.income.p":
      "Let's start with entering all the ways you earn money.",
    "topheader.expense.h2": "Expenses",
    "topheader.expense.p":
      "Add your expenses below. E.g. rent, food, remittances, bills (phone, electricity etc.) and travel.",
    "topheader.budget_summary.p":
      "Well done on completing your budget plan, let’s see how much money you’ve saved.",
    "card.other_income.h4": "Other Income",
    "card.other_income.p":
      "Do you earn any other income? E.g. government benefits, second job, rental income, driving a Grab, shares, etc.",
    "topnav.setting.h1": "Settings",
    "label.language": "Language",
    "label.currency": "Currency",
    "label.push_notification": "Push Notifications",
    "label.language.desc": "Select the app language",
    "label.currency.desc": "Select the app currency",
    "label.push_notification.desc": "Allow app to push notification",
    "label.not_supported": "Not supported",
    "button.view_all_operators": "View all operators",
    "logo.supported.h3": "Supported by",
    "logo.managed.h3": "Managed by",
    "label.view_legend": "View legend",
    "label.legend": "Legend",
    "menu.about": "About",
    "menu.privacy_policy": "Privacy Policy",
    "menu.terms_and_conditions": "Terms and Conditions",
    "menu.disclaimer": "Disclaimer",
    "label.january": "January",
    "label.february": "‌February",
    "label.march": "March",
    "label.april": "April",
    "label.may": "‌May",
    "label.june": "June",
    "label.july": "July",
    "label.august": "August",
    "label.september": "September",
    "label.october": "‌October",
    "label.november": "November",
    "label.december": "December",
    "error.primary_income": "The primary income is required",
    "error.basic_wage": "The basic wage is required",
    "button.apply": "Apply",
    "button.clear": "Clear",
    "economic.hardship":
      "Possible economic hardship may occur if you continue to spend at this rate.",
    "play.video": "Play Video",
    "label.download": "Download",
    "label.download_summary": "Download Summary",
    "label.phone": "Phone",
    "label.fax": "Fax",
    "label.email": "Email",
    "label.website": "Website",
    "button.send_money": "Send Money",
    "label.fx": "FX",
    "label.more_options_available": "More transfer options available",
    "label.send_method": "Send Method",
    "label.receive_method": "Receive Method",
    "button.more_details": "More Details",
    "label.no_additional_fees": "No additional fees",
    "label.additional_fee": "* Receiving fees may apply",
    "onesignal.promptOptions.actionMessage":
      "We'd like to send you push notifications. You can unsubscribe at any time.",
    "onesignal.promptOptions.exampleNotificationTitleDesktop":
      "SaverAsia notification",
    "onesignal.promptOptions.exampleNotificationMessageDesktop":
      "Notifications will appear on your desktop",
    "onesignal.promptOptions.exampleNotificationTitleMobile":
      "SaverAsia notification",
    "onesignal.promptOptions.exampleNotificationMessageMobile":
      "Notifications will appear on your device",
    "onesignal.promptOptions.exampleNotificationCaption":
      "(you can unsubscribe anytime)",
    "onesignal.promptOptions.acceptButton": "ALLOW",
    "onesignal.promptOptions.acceptButtonText": "ALLOW",
    "onesignal.promptOptions.cancelButton": "NO THANKS",
    "onesignal.promptOptions.cancelButtonText": "NO THANKS",
    "onesignal.welcomeNotification.title": "Welcome to SaverAsia",
    "onesignal.welcomeNotification.message": "Thanks for subscribing!",
    "onesignal.notifyButton.text.tipStateUnsubscribed":
      "Subscribe to notifications",
    "onesignal.notifyButton.text.tipStateSubscribed":
      "You're subscribed to notifications",
    "onesignal.notifyButton.text.tipStateBlocked":
      "You've blocked notifications",
    "onesignal.notifyButton.text.messageActionSubscribed":
      "Thanks for subscribing!",
    "onesignal.notifyButton.text.messageActionUnsubscribed":
      "You won't receive notifications again",
    "onesignal.notifyButton.text.dialogMainTitle": "Manage Site Notifications",
    "onesignal.notifyButton.text.dialogMainButtonSubscribe": "SUBSCRIBE",
    "onesignal.notifyButton.text.dialogMainButtonUnsubscribe": "UNSUBSCRIBE",
    "onesignal.notifyButton.text.dialogBlockedTitle": "Unblock Notifications",
    "onesignal.notifyButton.text.dialogBlockedMessage":
      "Follow these instructions to allow notifications:",
  },
  ID: {
    "topheader.compare.help": "Butuh bantuan? Lihat panduan video kami.",
    "carousel.item1.h2": "Menghemat waktu",
    "carousel.item2.h2": "Menghemat Uang",
    "carousel.item3.h2": "Menghemat/Menabung untuk Masa Depan Anda",
    "carousel.item1.p":
      "Hemat waktu dengan membandingkan secara cepat penyedia jasa transfer uang - untuk membantu Anda menemukan harga dan produk terbaik saat mengirimkan uang ke rumah",
    "carousel.item2.p":
      "Pelajari bagaimana cara menghemat uang dengan membandingkan penyedia jasa transfer uang dan menggunakan kalkulator SaverAsia",
    "carousel.item3.p":
      "Pelajari bagaimana cara menghemat untuk masa depan Anda dan meningkatkan keuangan Anda melalui panduan dasar keuangan kami",
    "button.start": "Mulakan",
    "topnav.compare.form.h1": "Perbandingan Tarif",
    "topheader.compare.form.h2": "Menghemat sewaktu mentransfer uang",
    "topheader.compare.form.p":
      "Bandingkan biaya dari masing-masing perusahaan yang menawarkan layanan transfer uang",
    "label.country_from": "Mengirim uang dari",
    "label.country_to": "Dikirim ke",
    "label.how_much": "Berapa banyak",
    "button.compare": "Bandingkan tarif sekarang",
    "topheader.compare.list.h2": "Hasil Perbandingan",
    "topheader.compare.list.green":
      "Hasil ditayangkan mulai hari ini, {date_today}. ",
    "topheader.compare.list.amber": "Hasil dikumpulkan pada {date_updated}. ",
    "topheader.compare.list.red":
      "Hasil dikumpulkan melalui belanja misteri di {date_updated}. ",
    "topheader.compare.list.p":
      "Kirim uang dari {country_from} ke {country_to} dengan SaverAsia",
    "bottomheader.compare.list.p":
      "Hasil ini mungkin tidak mencakup semua layanan yang tersedia untuk koridor pengiriman uang ini. Informasi di atas mencakup semua biaya yang diketahui.",
    "topnav.resource.h1": "Dasar-dasar keuangan",
    "topheader.resource.form.h2": "Dasar-dasar keuangan",
    "topheader.resource.form.p":
      "Untuk membantu Anda menghemat atau mengirim uang Anda, silakan baca kiat-kiat Dasar-Dasar Keuangan kami.",
    "topnav.calculators.h1": "Kalkulator",
    "card1.calculators.h3": "Kalkulator Overtime",
    "card1.calculators.p":
      "Jika Anda bekerja di Malaysia, Anda dapat menggunakan kalkulator ini untuk menghitung berapa seharusnya uang lembur yang Anda terima.",
    "card2.calculators.h3": "Kalkulator Anggaran",
    "card2.calculators.p":
      "Tambahkan penghasilan dan pengeluaran Anda untuk melihat seberapa banyak Anda bisa menabung dan memastikan Anda tidak memiliki pengeluaran berlebihan.",
    "breadcrumbs.step": "Langkah {step} dari {count}",
    "topnav.overtime.h1": "Kalkulator Overtime",
    "topheader.overtime.h2": "Kalkulator Pembayaran Lembur Malaysia",
    "topheader.overtime.p":
      "Kalkulator ini akan menghitung jumlah lembur yang seharusnya Anda terima berdasarkan jam kerja Anda dan persyaratan lembur berdesarkan standar pemerintah.",
    "label.hourly_rate": "Tarif per jam Anda adalah ",
    "label.overtime_hours.h4": "Jam lembur",
    "label.overtime_hours.p":
      "Sesuai Undang-Undang Ketenagakerjaan 1955 (Malaysia), karyawan harus dibayar dengan tarif tidak kurang dari 1,5 kali tarif per jam untuk kerja lembur melebihi jam kerja normal. Untuk bekerja pada hari diluar jadwal kerja, upahnya harus tidak kurang dari 2,0 kali tarif per jam, dan pada hari libur umum tidak kurang dari 3,0 kali tarif per jam.",
    "label.basic_wage": "Upah Dasar",
    "label.pay_period": "Periode Pembayaran",
    "label.amount": "Jumlah",
    "label.standard_days": "Hari Standar (x1.5)",
    "label.rest_days": "Hari Istirahat (x2)",
    "label.paid_public_holidays": "Libur Nasional Berbayar (x2)",
    "label.unpaid_public_holidays": "Libur Nasional Tidak Berbayar (x3)",
    "label.overtime": "Lembur",
    "label.total_expense": "Total Biaya",
    "label.total_income": "Total Pendapatan",
    "label.money_left": "Uang yang tersisa",
    "label.daily": "Setiap hari",
    "label.weekly": "Setiap minggu",
    "label.monthly": "Bulanan",
    "label.item": "Butir/Nomor Urut",
    "label.primary_income": "Pendapatan/Penghasilan Utama",
    "label.market_rate": "FX pasar menengah adalah {market_rate}",
    "label.operator": "Operator",
    "label.type": "Formulir transfer",
    "label.receiving": "Diterima",
    "label.rate": "Tarif",
    "label.fee": "Biaya",
    "label.form": "Untuk m",
    "label.method": "Metode transfer",
    "label.speed": "Kecepatan transfer",
    "label.number_overtime": "Jumlah jam kerja lembur",
    "topnav.faq.h1": "Pertanyaan yang Sering Diajukan",
    "topnav.video.h1": "Panduan",
    "topheader.video.form.h2": "Panduan Video",
    "topheader.video.form.p":
      "Silakan gunakan panduan video kami untuk membantu Anda memahami cara menggunakan fungsi Aplikasi SaverAsia.",
    "bottom.faq.p":
      "SaverAsia memiliki jawaban untuk sebagian besar pertanyaan Anda tetapi jika Anda memerlukan informasi lebih lanjut, silakan hubungi kami di <a>enquiries@saver.global</a> dan kami akan mencoba membantu Anda.",
    "notfound.h2": '"Oh - Uh"',
    "notfound.p":
      "Maaf, kami tidak dapat menemukan layar yang Anda cari. Gunakan navigasi di bawah ini.",
    "topheader.summary.h2": "Ringkasan",
    "topheader.summary.p":
      "Di bawah ini Anda dapat melihat berapa banyak uang yang seharusnya Anda terima untuk periode ini.",
    "button.back": "Kembali",
    "button.prev": "Kembali",
    "button.next": "Selanjutnya",
    "button.remove": "Menghapus",
    "button.add_income": "Tambahkan Penghasilan",
    "button.add_expense": "Tambahkan/Masukkan jumlah pengeluaran/biaya-biaya",
    "button.read_more": "Baca lebih lajut",
    "button.show_less": "Tampilkan lebih sedikit",
    "topnav.budget.h1": "Kalkulator Anggaran",
    "topheader.income.h2": "Pendapatan/Penghasilan",
    "topheader.income.p":
      "Mari kita mulai dengan memasukkan semua informasi bagaiman cara Anda menghasilkan/mendapatkan uang.",
    "topheader.expense.h2": "Pengeluaran/Biaya-biaya",
    "topheader.expense.p":
      "Tambahkan/Masukkan jumlah pengeluaran Anda di bawah ini. Cobalah untuk mengingat semua yang perlu Anda bayar termasuk kartu kredit, pinjaman, dan tagihan.",
    "topheader.budget_summary.p":
      "Selamat, Anda telah menyelesaikan rencana anggaran Anda, mari kita lihat berapa banyak uang yang dapat Anda tabung.",
    "card.other_income.h4": "Pendapatan/Penghasilan lainnya",
    "card.other_income.p":
      "Apakah Anda mendapatkan penghasilan lainnya? Misalnya: tunjangan pemerintah, pekerjaan sampingan, pendapatan sewa, mengendarai Grab, saham, dll.",
    "topnav.setting.h1": "Pengaturan",
    "label.language": "Bahasa",
    "label.currency": "Mata uang",
    "label.push_notification": "Pemberitahuan Push",
    "label.language.desc": "Pilih bahasa di aplikasi",
    "label.currency.desc": "Pilih mata uang di aplikasi",
    "label.push_notification.desc": "Izinkan aplikasi mendorong pemberitahuan",
    "label.not_supported": "Tidak didukung",
    "button.view_all_operators": "Lihat semua operator",
    "logo.supported.h3": "Didukung oleh",
    "logo.managed.h3": "Dikelola oleh",
    "label.view_legend": "Lihat riwayat",
    "label.legend": "Riwayat",
    "menu.about": "Tentang",
    "menu.privacy_policy": "Kebijakan pribadi",
    "menu.terms_and_conditions": "Syarat dan ketentuan",
    "menu.disclaimer": "Penolakan",
    "label.january": "Januari",
    "label.february": "Februari",
    "label.march": "Maret",
    "label.april": "April",
    "label.may": "Mei",
    "label.june": "Juni",
    "label.july": "Juli",
    "label.august": "Agustus",
    "label.september": "September",
    "label.october": "Oktober",
    "label.november": "November",
    "label.december": "Desember",
    "error.primary_income": "Penghasilan utama diperlukan",
    "error.basic_wage": "Upah dasar diperlukan",
    "button.apply": "Menerapkan",
    "button.clear": "Bersih",
    "economic.hardship":
      "Kesulitan ekonomi yang mungkin dapat terjadi jika Anda terus menghabiskan pada tingkat ini.",
    "play.video": "mainkan video",
    "label.download": "Unduh",
    "label.download_summary": "Unduh Ringkasan",
    "label.phone": "Telepon",
    "label.fax": "Fax",
    "label.email": "Surel",
    "label.website": "Situs web",
    "button.send_money": "Mengirim uang",
    "label.fx": "FX",
    "label.more_options_available": "Lebih banyak opsi transfer tersedia",
    "label.send_method": "Metode Kirim",
    "label.receive_method": "Terima Metode",
    "button.more_details": "Keterangan lebih lanjut",
    "label.no_additional_fees": "Tidak ada biaya tambahan",
    "label.additional_fee": "* Biaya penerimaan mungkin berlaku",
    "onesignal.promptOptions.actionMessage":
      "Kami ingin mengirimkan pemberitahuan push kepada Anda. Anda dapat berhenti berlangganan kapan saja.",
    "onesignal.promptOptions.exampleNotificationTitleDesktop":
      "Notifikasi SaverAsia",
    "onesignal.promptOptions.exampleNotificationMessageDesktop":
      "Notifikasi akan muncul di desktop Anda",
    "onesignal.promptOptions.exampleNotificationTitleMobile":
      "Notifikasi SaverAsia",
    "onesignal.promptOptions.exampleNotificationMessageMobile":
      "Notifikasi akan muncul di perangkat Anda",
    "onesignal.promptOptions.exampleNotificationCaption":
      "(Anda dapat berhenti berlangganan kapan saja)",
    "onesignal.promptOptions.acceptButton": "MENGIZINKAN",
    "onesignal.promptOptions.acceptButtonText": "MENGIZINKAN",
    "onesignal.promptOptions.cancelButton": "TIDAK, TERIMA KASIH",
    "onesignal.promptOptions.cancelButtonText": "TIDAK, TERIMA KASIH",
    "onesignal.welcomeNotification.title": "Selamat datang di SaverAsia",
    "onesignal.welcomeNotification.message": "Terima kasih telah berlangganan!",
    "onesignal.notifyButton.text.tipStateUnsubscribed":
      "Berlangganan notifikasi",
    "onesignal.notifyButton.text.tipStateSubscribed":
      "Anda berlangganan notifikasi",
    "onesignal.notifyButton.text.tipStateBlocked":
      "Anda telah memblokir notifikasi",
    "onesignal.notifyButton.text.messageActionSubscribed":
      "Terima kasih telah berlangganan!",
    "onesignal.notifyButton.text.messageActionUnsubscribed":
      "Anda tidak akan menerima notifikasi lagi",
    "onesignal.notifyButton.text.dialogMainTitle": "Kelola Pemberitahuan Situs",
    "onesignal.notifyButton.text.dialogMainButtonSubscribe": "LANGGANAN",
    "onesignal.notifyButton.text.dialogMainButtonUnsubscribe":
      "BERHENTI BERLANGGANAN",
    "onesignal.notifyButton.text.dialogBlockedTitle":
      "Buka Blokir Pemberitahuan",
    "onesignal.notifyButton.text.dialogBlockedMessage":
      "Ikuti petunjuk berikut untuk mengizinkan pemberitahuan:",
  },
  KM: {
    "topheader.compare.help":
      "ត្រូវការ​ជំនួយ? សូមពិនិត្យមើលការណែនាំវីដេអូរបស់យើង។",
    "carousel.item1.h2": "សន្សំពេលវេលា",
    "carousel.item2.h2": "សន្សំប្រាក់",
    "carousel.item3.h2": "សន្សំដើម្បីអនាគតរបស់អ្នក",
    "carousel.item1.p":
      "សន្សំពេលវេលាដោយប្រៀបធៀបអ្នកផ្តល់សេវាផ្ទេរប្រាក់  - ដើម្បីជួយអ្នកស្វែងរកតម្លៃ និងផលិតផលល្អបំផុត នៅពេលដែលអ្នកផ្ញើប្រាក់មកផ្ទះ",
    "carousel.item2.p":
      "រៀនសន្សំប្រាក់ដោយប្រៀបធៀបអ្នកផ្ទេរប្រាក់ និងប្រើប្រាស់កម្មវិធីគណនា SaverAsia",
    "carousel.item3.p":
      "រៀនពីរបៀបសន្សំសម្រាប់អនាគតរបស់អ្នក និងធ្វើឱ្យប្រសើរឡើងនូវហិរញ្ញវត្ថុរបស់អ្នកតាមការណែនាំងាយៗផ្នែកហិរញ្ញវត្ថុរបស់យើង",
    "button.start": "ចាប់ផ្តើមពីឥលូវនេះ",
    "topnav.compare.form.h1": "អត្រាប្រៀបធៀប",
    "topheader.compare.form.h2": "សន្សំពីអ្នកផ្ទេរប្រាក់",
    "topheader.compare.form.p":
      "ប្រៀបធៀបតម្លៃនៃក្រុមហ៊ុនដែលផ្តល់សេវាកម្មផ្ទេរប្រាក់",
    "label.country_from": "ផ្ញើប្រាក់ពី",
    "label.country_to": "ផ្ញើទៅ",
    "label.how_much": "ចំនួន",
    "button.compare": "ប្រៀបធៀបអត្រាបច្ចុប្បន្ន",
    "topheader.compare.list.h2": "លទ្ធផលប្រៀបធៀប",
    "topheader.compare.list.green":
      "លទ្ធផលគឺផ្សាយបន្តផ្ទាល់ដូចសព្វថ្ងៃនេះ {date_today}. ",
    "topheader.compare.list.amber": "លទ្ធផលត្រូវបានប្រមូល {date_updated}. ",
    "topheader.compare.list.red":
      "លទ្ធផលត្រូវបានប្រមូលតាមរយៈការទិញឥវ៉ាន់អាថ៌កំបាំង {date_updated}. ",
    "topheader.compare.list.p":
      "ញេីប្រាក់ពីចំនួន {country_from} ដល់ {country_to} ជាមួយ SaverAsia",
    "bottomheader.compare.list.p":
      "លទ្ធផលទាំងនេះអាចមិនរាប់បញ្ចូលសេវាកម្មទាំងអស់ដែលមានសម្រាប់ច្រករបៀងផ្ទេរប្រាក់នេះ។ ព័ត៌មានខាងលើរាប់បញ្ចូលថ្លៃដើមដែលគេដឹង។",
    "topnav.resource.h1": "មូលដ្ឋានហិរញ្ញវត្ថុ",
    "topheader.resource.form.h2": "មូលដ្ឋានហិរញ្ញវត្ថុ",
    "topheader.resource.form.p":
      "ដើម្បីជួយអ្នកសន្សំប្រាក់ឬផ្ញើប្រាក់របស់អ្នកសូមអានព័ត៌មានជំនួយហិរញ្ញវត្ថុរបស់យើង។",
    "topnav.calculators.h1": "កម្មវិធីគណនា",
    "card1.calculators.h3": "កម្មវិធីគណនាប្រាក់បន្ថែមម៉ោង",
    "card1.calculators.p":
      "ប្រសិនបើអ្នកកំពុងធ្វើការនៅម៉ាឡេស៊ី អ្នកអាចប្រើប្រាស់កម្មវិធីគណនានេះដើម្បីរកប្រាក់បន្ថែមម៉ោងដែលអ្នកគួរទទួល",
    "card2.calculators.h3": "កម្មវិធីគណនាថវិកា",
    "card2.calculators.p":
      "បន្ថែមចំណូល និងចំណាយរបស់អ្នក ដើម្បីមើលឃើញថាចំនួនទឹកប្រាក់ប៉ុន្មានដែលអ្នកអាចសន្សំ និងដើម្បីប្រាកដថាអ្នកមិនចាយលុយលើស",
    "breadcrumbs.step": "ជំហាន {step} លើ {count}",
    "topnav.overtime.h1": "កម្មវិធីគណនាប្រាក់បន្ថែមម៉ោង",
    "topheader.overtime.h2": "កម្មវិធីគណនាប្រាក់បន្ថែមម៉ោងម៉ាឡេស៊ី",
    "topheader.overtime.p":
      "កម្មវិធីគណនានេះ នឹងគណនានូវចំនួនប្រាប់បន្ថែមម៉ោងដែលអ្នកគួរទទួលបានផ្អែកលើម៉ោងធ្វើការដែលអ្នកបានបំពេញ និងលក្ខខណ្ឌសម្រាប់ការងារបន្ថែមម៉ោងតាមការកំណត់របស់រដ្ឋាភិបាល",
    "label.hourly_rate": "អត្រាថ្លៃឈ្នួលក្នុងមួយម៉ោងរបស់អ្នកគឺ ",
    "label.overtime_hours.h4": "ម៉ោងធ្វើការបន្ថែម",
    "label.overtime_hours.p":
      "យោងតាមច្បាប់ស្តីពីការងារឆ្នាំ ១៩៥៥ (ប្រទេសម៉ាឡេស៊ី) និយោជិតត្រូវទទួលបានអត្រាប្រាក់ឈ្នួលមិនតិចជាង ១,៥ ដងក្នុងមួយម៉ោងរបស់គាត់ចំពោះការងារបន្ថែមម៉ោងក្នុងករណីលើសម៉ោងធ្វើការធម្មតា។ សម្រាប់ការងារនៅថ្ងៃសម្រាក ប្រាក់ឈ្នួលមិនត្រូវតិចជាង ២ ដងនៃអត្រាប្រាក់ឈ្នួលក្នុងមួយម៉ោងទេ ហើយនៅថ្ងៃសម្រាកសាធារណៈមិនតិចជាង ៣ ដងនៃអត្រាប្រាក់ឈ្នួលក្នុងមួយម៉ោងទេ។",
    "label.basic_wage": "ប្រាក់ឈ្នួលគោល",
    "label.pay_period": "រយៈពេលធ្វើការ",
    "label.amount": "ចំនួន",
    "label.standard_days": "ថ្ងៃធម្មតា (x១,៥)",
    "label.rest_days": "ថ្ងៃសម្រាក (x២)",
    "label.paid_public_holidays": "ថ្ងៃឈប់សម្រាក​ដោយទទួលបាន​ប្រាក់ឈ្នួល​",
    "label.unpaid_public_holidays": "ថ្ងៃឈប់​សម្រាក​ដោយមិនទទួលបានប្រាក់ឈ្នួល​",
    "label.overtime": "ម៉ោងបន្ថែម",
    "label.total_expense": "ចំណាយ​សរុប​",
    "label.total_income": "ចំណូលសរុប",
    "label.money_left": "ប្រាក់នៅសល់",
    "label.daily": "ប្រចាំថ្ចៃ",
    "label.weekly": "ប្រចាំសប្តាហ៍",
    "label.monthly": "ប្រចាំខែ",
    "label.item": "មុខបញ្ជី",
    "label.primary_income": "ចំណូលបឋម",
    "label.market_rate": "អត្រាប្តូរប្រាក់លើទីផ្សារជាមធ្យមគឺ {market_rate}",
    "label.operator": "ប្រតិបត្តិករ",
    "label.type": "ទម្រង់នៃការ​ផ្ទេរ​ប្រាក់​",
    "label.receiving": "ការទទួល",
    "label.rate": "អត្រាប្តូរប្រាក់",
    "label.fee": "ថ្លៃសេវា",
    "label.form": "សំណុំបែបបទ",
    "label.method": "វិធីសាស្ត្រផ្ទេរប្រាក់",
    "label.speed": "ល្បឿននៃការផ្ទេរប្រាក់",
    "label.number_overtime": "ចំនួនម៉ោងដែលបានធ្វើការបន្ថែមម៉ោង",
    "topnav.faq.h1": "សំណួរ​ដែលត្រូវ​បាន​សួរជា​ញឹកញាប់​",
    "topnav.video.h1": "មគ្គុទេសក៍",
    "topheader.video.form.h2": "វីដេអូណែនាំ",
    "topheader.video.form.p":
      "សូមប្រើការណែនាំវីដេអូរបស់យើងដើម្បីជួយអ្នកឱ្យយល់ពីរបៀបប្រើប្រាស់មុខងារ SaverAsia App ។",
    "bottom.faq.p":
      "​SaverAsia អាច​ស្រាយចម្ងល់​ភាគច្រើនរបស់អ្នក ប៉ុន្តែប្រសិនបើ​អ្នកត្រូវការ​ព័ត៌មានបន្ថែម​ សូមទាក់ទង​មកយើង​ខ្ញុំតាមរយៈ<a>enquiries@saver.global</a> ហើយ​យើង​នឹងខិតខំជួយដល់អ្នក។",
    "notfound.h2": '"Oh - Uh"',
    "notfound.p":
      "សូមទោស! យើង​រកមិនឃើញផ្ទាំងបង្ហាញដែលអ្នកកំពុង​ស្វែងរក​ទេ។ សូមប្រើប្រាស់សញ្ញាស្វែងរក (navigation) ខាងក្រោម។",
    "topheader.summary.h2": "សង្ខេប",
    "topheader.summary.p":
      "ខាងក្រោមនេះអ្នកអាចដឹងថាតើអ្នកគួរទទួលបានប្រាក់ប៉ុន្មានសម្រាប់រយៈពេលនេះ។",
    "label.no_results": "រកមិនឃើញលទ្ធផលដែលមានពាក្យចម្រោះទាំងអស់របស់អ្នកទេ។", // To translate
    "button.back": "ចេញ",
    "button.prev": "Prev", // To translate
    "button.next": "បន្ទាប់",
    "button.remove": "ដកចេញ",
    "button.add_income": "បន្ថែមចំណូល",
    "button.add_expense": "បន្ថែមចំណាយ",
    "button.read_more": "អានបន្ថែម",
    "button.show_less": "បង្រួម",
    "topnav.budget.h1": "កម្មវិធីគណនាថវិកា",
    "topheader.income.h2": "ចំណូល",
    "topheader.income.p":
      "ចាប់ផ្តើមដោយការដាក់បញ្ចូលគ្រប់វិធីដែលអ្នករកប្រាក់បាន។",
    "topheader.expense.h2": "ចំណាយ",
    "topheader.expense.p":
      "បញ្ចូលចំណាយរបស់អ្នកខាងក្រោម។ ព្យាយាមគិតនូវអ្វីដែលត្រូវចាយវាយ រាប់បញ្ចូលទាំង កាតឥណទាន កម្ចី និងវិក័យបត្រផ្សេងៗ។",
    "topheader.budget_summary.p":
      "ពិតជាល្អណាស់សម្រាប់ការបំពេញផែនការថវិការបស់អ្នក តោះមើលចំនួនប្រាក់ដែលអ្នកសន្សំបាន",
    "card.other_income.h4": "ចំណូលផ្សេងៗ",
    "card.other_income.p":
      "តើអ្នកមានចំណូលផ្សេងឬទេ? ឧទាហរណ៍ តាវកាលិករបស់រដ្ឋាភិបាល ការងារបន្ទាប់បន្សំ ចំណូលពីការជួល បើកបរ Grab ភាគហ៊ុន ។ល។",
    "topnav.setting.h1": "ការបង្កើត",
    "label.language": "ភាសា",
    "label.currency": "រូបិយប័ណ្ណ",
    "label.push_notification": "ចុចលើកាជូនដំណឹង",
    "label.language.desc": "ជ្រើសរើសកម្មវិធីភាសា",
    "label.currency.desc": "ជ្រើសរើសកម្មវិធីរូបិយប័ណ្ណ",
    "label.push_notification.desc": "អនុញ្ញាត្តកម្មវិធីធ្វើកាជូនដំណឹង",
    "label.not_supported": "មិនត្រូវបានគាំទ្រ​",
    "button.view_all_operators": "មើល​ប្រតិបត្តិករទាំងអស់​",
    "logo.supported.h3": "គាំទ្រ​ដោយ",
    "logo.managed.h3": "គ្រប់គ្រង​ដោយ​",
    "label.view_legend": "មើល​ប្រវត្តិ",
    "label.legend": "ប្រវត្តិ",
    "menu.about": "ស្តីពី​",
    "menu.privacy_policy": "គោលនយោបាយ​​​ឯកជនភាព​",
    "menu.terms_and_conditions": "លក្ខខណ្ឌ​",
    "menu.disclaimer": "ការបដិសេធការ​ទទួលខុសត្រូវ​",
    "label.january": "មករា",
    "label.february": "កុម្ភៈ",
    "label.march": "មីនា",
    "label.april": "មេសា",
    "label.may": "ឧសភា",
    "label.june": "មិថុនា",
    "label.july": "កក្កដា",
    "label.august": "សីហា",
    "label.september": "កញ្ញា",
    "label.october": "តុលា",
    "label.november": "វិច្ឆិកា",
    "label.december": "ធ្នូ",
    "error.primary_income": "ចាំបាច់ត្រូវ​មាន​ប្រាក់​ចំណូល​បឋម​",
    "error.basic_wage": "ចាំបាច់ត្រូវមាន​ប្រាក់ឈ្នួលគោល​",
    "button.apply": "សម្រេច​យក​",
    "button.clear": "ច្បាស់",
    "economic.hardship":
      "ការលំបាកខាងសេដ្ឋកិច្ចដែលអាចកើតមានប្រសិនបើអ្នកបន្តចំណាយក្នុងអត្រានេះ។",
    "play.video": "ចាក់វីដេអូ",
    "label.download": "ទាញយក",
    "label.download_summary": "ទាញយកសង្ខេប",
    "label.phone": "ទូរស័ព្",
    "label.fax": "ទូរសារ",
    "label.email": "អ៊ីមែល",
    "label.website": "គេហទំព័រ",
    "button.send_money": "ផ្ញើប្រាក់",
    "label.fx": "អេច។ អេ",
    "label.more_options_available": "មានជម្រើសផ្ទេរច្រើនទៀត",
    "label.send_method": "វិធីសាស្រ្តផ្ញើ",
    "label.receive_method": "ទទួលវិធីសាស្រ្ត",
    "button.more_details": "ព័ត៌មានលំអិត",
    "label.no_additional_fees": "មិនគិតថ្លៃបន្ថែម",
    "label.additional_fee": "* ថ្លៃសេវាទទួលអាចអនុវត្ត",
    "onesignal.promptOptions.actionMessage":
      "သင့်အား တွန်းအားပေး အသိပေးချက်များကို ကျွန်ုပ်တို့ ပေးပို့လိုပါသည်။ သင်သည် အချိန်မရွေး စာရင်းသွင်းမှုကို ပယ်ဖျက်နိုင်သည်။",
    "onesignal.promptOptions.exampleNotificationTitleDesktop":
      "SaverAsia အသိပေးချက်",
    "onesignal.promptOptions.exampleNotificationMessageDesktop":
      "အကြောင်းကြားချက်များသည် သင့်ဒက်စ်တော့ပေါ်တွင် ပေါ်လာလိမ့်မည်။",
    "onesignal.promptOptions.exampleNotificationTitleMobile":
      "SaverAsia အသိပေးချက်",
    "onesignal.promptOptions.exampleNotificationMessageMobile":
      "အကြောင်းကြားချက်များသည် သင့်စက်တွင် ပေါ်လာလိမ့်မည်။",
    "onesignal.promptOptions.exampleNotificationCaption":
      "(စာရင်းသွင်းမှုကို အချိန်မရွေး ပယ်ဖျက်နိုင်ပါတယ်)",
    "onesignal.promptOptions.acceptButton": "អនុញ្ញាត",
    "onesignal.promptOptions.acceptButtonText": "អនុញ្ញាត",
    "onesignal.promptOptions.cancelButton": "អត់អី​ទេ​អរគុណ​ហើយ",
    "onesignal.promptOptions.cancelButtonText": "អត់អី​ទេ​អរគុណ​ហើយ",
    "onesignal.welcomeNotification.title": "សូមស្វាគមន៍មកកាន់ SaverAsia",
    "onesignal.welcomeNotification.message": "អរគុណសម្រាប់ការជាវ!",
    "onesignal.notifyButton.text.tipStateUnsubscribed": "ជាវការជូនដំណឹង",
    "onesignal.notifyButton.text.tipStateSubscribed": "អ្នកបានជាវការជូនដំណឹង",
    "onesignal.notifyButton.text.tipStateBlocked":
      "អ្នកបានទប់ស្កាត់ការជូនដំណឹង",
    "onesignal.notifyButton.text.messageActionSubscribed":
      "អរគុណសម្រាប់ការជាវ!",
    "onesignal.notifyButton.text.messageActionUnsubscribed":
      "អ្នកនឹងមិនទទួលបានការជូនដំណឹងម្តងទៀតទេ។",
    "onesignal.notifyButton.text.dialogMainTitle":
      "គ្រប់គ្រងការជូនដំណឹងគេហទំព័រ",
    "onesignal.notifyButton.text.dialogMainButtonSubscribe": "ជាវ",
    "onesignal.notifyButton.text.dialogMainButtonUnsubscribe": "ឈប់ជាវ",
    "onesignal.notifyButton.text.dialogBlockedTitle": "ឈប់ទប់ស្កាត់ការជូនដំណឹង",
    "onesignal.notifyButton.text.dialogBlockedMessage":
      "អនុវត្តតាមការណែនាំទាំងនេះ ដើម្បីអនុញ្ញាតការជូនដំណឹង៖",
  },
  MY: {
    "topheader.compare.help":
      "အကူအညီလိုတယ်? ကျွန်ုပ်တို့၏ ဗီဒီယိုလမ်းညွှန်များကို ကြည့်ရှုပါ။",
    "carousel.item1.h2": "အချိန်ကုန်သက်သာခြင်း",
    "carousel.item2.h2": "‌ငွေကြေးစုဆောင်းခြင်း",
    "carousel.item3.h2": "အနာဂတ်အတွက် ရင်းနှီးမြှုပ်နှံခြင်း",
    "carousel.item1.p":
      "အိမ်သို့ငွေလွှဲပို့ရာတွင် ငွေလဲနှုန်းအများဆုံးပေးသည့် ငွေလွှဲဝန်ဆောင်မှုများကို အချိန်တိုအတွင်း နှိုင်းယှဉ်ဖေါ်ပြပေးနိုင်ခြင်းကြောင့် အချိန်ကုန်သက်သာစေသည်။",
    "carousel.item2.p":
      "‌ငွေလွှဲဝန်ဆောင်မှုပေးသူများကို နှိုင်းယှဉ်ခြင်းနှင့် SaverAsia ဂဏာန်းတွက်စက်ကို အသုံးပြုခြင်းများအားဖြင့် မည်သို့ ငွေကြေးစုဆောင်းရမလဲဆိုသည်ကို သင်ယူနိုင်ပေသည်။",
    "carousel.item3.p":
      "‌ကျွန်ုပ်တို့၏ အခြေခံငွေကြေးဆိုင်ရာ လမ်းညွှန်ချက်များကို အသုံးပြုခြင်းအားဖြင့် သင်၏ အနာဂတ်အတွက် ရင်းနှီးမြှုပ်နှံ နိုင်မည့် နည်းလမ်းများကို သိရှိလာနိုင်ပြီး သင်၏ ငွေကြေးစီမံမှုများ တိုးတက်လာမည် ဖြစ်သည်",
    "button.start": "စတင်ရန်",
    "topnav.compare.form.h1": "နှုန်းထားများ နှိုင်းယှဉ်ခြင်း",
    "topheader.compare.form.h2": "သက်သာသောနှုန်းဖြင့် ငွေလွှဲခြင်းများ",
    "topheader.compare.form.p":
      "ငွေလွှဲဝန်ဆောင်မှုများအတွက် ကုမ္ပဏီများ၏ ဝန်ဆောင်ခများကို နှိုင်းယှဉ်ခြင်း",
    "label.country_from": "‌ငွေလွှဲမည့်နိုင်ငံ",
    "label.country_to": "‌ငွေလက်ခံမည့်နိုင်ငံ",
    "label.how_much": "လွှဲပို့ချင်သည့် ပမာဏ",
    "button.compare": "နှုန်းထားများကို နှိုင်းယှဉ်ကြည့်ခြင်း",
    "topheader.compare.list.h2": "ရလဒ်များကို နှို်းယှဉ်ခြင်း",
    "topheader.compare.list.green":
      "ရလဒ်များကို {date_today} ဒီနေ့၏တိုက်ရိုက်ထုတ်လွှင့် ",
    "topheader.compare.list.amber":
      "ရလဒ်များကို {date_updated} တွင်စုဆောင်းထားပါတယ် ",
    "topheader.compare.list.red":
      "ရလာဒ်များကို {date_updated} တွင်လျှို့ဝှက်ဆန်းကြယ်သောစျေးဝယ်ခြင်းမှကောက်ယူခဲ့သည်။ ",
    "topheader.compare.list.p":
      "ဖြင့် {country_from} မှ {country_to} သို့ ငွေလွှဲပို့နိုင်သည်။",
    "bottomheader.compare.list.p":
      "ဤရလဒ်တွင်ဤငွေလွှဲပို့သည့်လမ်းကြောင်းအတွက်ရရှိနိုင်သော ၀ န်ဆောင်မှုအားလုံးမပါ ၀ င်ပါ။ အထက်ပါအချက်အလက်များအားလုံးတွင်လူသိများသောကုန်ကျစရိတ်များပါဝင်သည်။",
    "topnav.resource.h1": "အခြေခံငွေကြေးပညာ",
    "topheader.resource.form.h2": "အခြေခံငွေကြေးပညာ",
    "topheader.resource.form.p":
      "သင်၏ငွေစုဆောင်းရန်သို့မဟုတ်ပေးပို့ရန်ကျွန်ုပ်တို့ဘဏ္Financeာရေးအခြေခံအကြံပြုချက်များကို ကျေးဇူးပြု၍ ဖတ်ပါ။",
    "topnav.calculators.h1": "ဂဏာန်းတွက်စက်များ",
    "card1.calculators.h3":
      "အချိန်ပိုလုပ်အားခ တွက်ချက်ခြင်းအတွက် ဂဏာန်းတွက်စက်များ",
    "card1.calculators.p":
      "သင် မလေးရှားတွင် အလုပ်လုပ်ကိုင်နေပါက သင် ရရှိသင့်သည့် အချိန်ပိုလုပ်အားခကို သိရှိရန် ဤဂဏာန်းတွက်စက်ကို အသုံးပြု၍ တွက်ချက်နိုင်သည်",
    "card2.calculators.h3": "ဘတ်ဂျက် ဂဏာန်းတွက်စက်",
    "card2.calculators.p":
      "သင်ငွေမည်မျှစုဆောင်းနိုင်မည်ကို သိရှိရန်နှင့် ငွေအပိုမသုံးမိစေရန် သင်၏ ဝင်ငွေနှင့် အသုံးစရိတ်များကို ဖြည့်သွင်းလိုက်ပါ။",
    "breadcrumbs.step": "Step {step} of {count}",
    "topnav.overtime.h1": "အချိန်ပို ဂဏာန်းတွက်စက်",
    "topheader.overtime.h2":
      "မလေးရှားနိုင်ငံအတွက် အချိန်ပိုလုပ်အားခ ဂဏာန်းတွက်စက်",
    "topheader.overtime.p":
      "ဤဂဏာန်းတွက်စက်သည် သင်လုပ်ကိုင်ခဲ့ရသည့် အချိန်ပို နာရီအလိုက် ရရှိသင့်သည့် လုပ်အားခ ပမာဏကို အစိုးရက သတ်မှတ်ထားသည့် အချိန်ပိုစံနှုန်းသတ်မှတ်ချက်အလိုက် တွက်ချက်ပေးခြင်းဖြစ်သည်။ (အချိန်ပို ဟုတ်ရဲ့လား)",
    "label.hourly_rate": "သင်၏ နာရီအလိုက် လုပ်အားခနှုန်း ",
    "label.overtime_hours.h4": "အချိန်ပို အလုပ်ချိန်",
    "label.overtime_hours.p":
      "အလုပ်အကိုင်အက်ဉပဒေ ၁၉၉၅ (မလေးရှား) အရ အလုပ်သမားသည် ပုံမှန်အလုပ်ချိန်ထက် ကျော်လွန်ပြီး အချိန်ပိုအလုပ်လုပ်ကိုင်ရပါက ပုံမှန်နာရီအလိုက်ရရှိသော လုပ်ခလစာနှုန်း၏ ၁.၅ ဆ ကို အချိန်ပိုအလုပ်ချိန်အတွက် ရရှိရမည်ဖြစ်သည်။ နားရက်တွင် အချိန်ပိုကို နာရီအလိုက်လုပ်ခလစာ၏ ၂ ဆ ပေးရမည်ဖြစ်ပြီး အများပြည်သူအားလပ်ရက်တွင် အလုပ်လုပ်ကိုင်ပါက နာရီအလိုက်လုပ်ခလစာ၏ ၃ ဆပေးရမည်ဖြစ်သည်။  ",
    "label.basic_wage": "အခြေခံလုပ်ခလစာ",
    "label.pay_period": "ရရှိသည့် ကာလအပိုင်းအခြား",
    "label.amount": "ပမာဏ",
    "label.standard_days": "ပုံမှန်အလုပ်လုပ်ကိုင်ရသောရက်များ (x ၁.၅)",
    "label.rest_days": "နားရက်များ (x ၂)",
    "label.public_holidays": "အများပြည်သူအားလပ်ရက်များ (x ၃)",
    "label.paid_public_holidays": "လုပ်ခရသော အများပြည်သူအားလပ်ရက်",
    "label.unpaid_public_holidays": "လုပ်ခမရသော အများပြည်သူအားလပ်ရက်",
    "label.overtime": "အချိန်ပို",
    "label.total_expense": "စုစုပေါင်းကုန်ကျငွေ",
    "label.total_income": "စုစုပေါင်း ဝင်ငွေ",
    "label.money_left": "လက်ကျန်ငွေ",
    "label.daily": "‌နေ့စဉ်",
    "label.weekly": "အပတ်စဉ်",
    "label.monthly": "လစဉ်",
    "label.item": "အကြောင်းအရာ",
    "label.primary_income": "ပင်မဝင်ငွေ",
    "label.market_rate": "FX ၏ အလယ်အလတ်ဈေးကွက် {market_rate}",
    "label.operator": "ဝန်ဆောင်မှုပေးသူ",
    "label.type": "‌လွှဲပို့မှုပုံစံ",
    "label.receiving": "လက်ခံရရှိသူ",
    "label.rate": "FX  နှုန်း",
    "label.fee": "အခ",
    "label.form": "ပုံစံ",
    "label.method": "လွှဲပို့မှုနည်းလမ်း",
    "label.speed": "‌ပြောင်းလဲမှုနှုန်း",
    "label.number_overtime": "အချိန်ပိုလုပ်ကိုင်ရသော နာရီ",
    "topnav.faq.h1": "အမေးများသောမေးခွန်းများ",
    "topnav.video.h1": "လမ်းညွှန်မျာ",
    "topheader.video.form.h2": "ဗွီဒီယိုလမ်းညွှန်များ",
    "topheader.video.form.p":
      "SaverAsia App လုပ်ဆောင်ချက်များကိုသင်မည်သို့နားလည်ရန်ကူညီရန်ကျွန်ုပ်တို့၏ဗီဒီယိုလမ်းညွှန်များကိုအသုံးပြုပါ။",
    "bottom.faq.p":
      "သင်၏ မေးခွန်းများအတွက် SaverAsia တွင် အဖြေရှိသည်။ အချက်အလက်များ ပိုမိုသိရှိလိုပါက <a>enquiries@saver.global</a> သို့ ဆက်သွယ်လိုက်ပါ။ ကျွန်ုပ်တို့ သင့်ကို ကူညီဖြေကြားပေးပါမည်။",
    "notfound.h2": '"Oh - Uh"',
    "notfound.p":
      "သင်ရှာဖွေနေသော စာမျက်နှာကို ရှာမတွေ့ပါ။ အောက်ဖေါ်ပြပါ လမ်းညွန်မှုအတိုင်း အသုံးပြုပါ။",
    "topheader.summary.h2": "စုစုပေါင်း",
    "topheader.summary.p": "‌အောက်တွင် ဤကာလအတွက် သင်မည်မျှရရှိရမည်ကို ကြည့်ပါ။",
    "label.no_results": "သင်၏စီစစ်မှုစည်းမျဉ်းများပါ ၀ င်သောရလဒ်မရှိပါ။",
    "button.back": "‌နောက်သို့",
    "button.prev": "‌နောက်သို့",
    "button.next": "‌နောက်တဆင့်",
    "button.remove": "ဖယ်ရှားရန်",
    "button.add_income": "ဝင်ငွေများ ထပ်ထည့်ရန်",
    "button.add_expense": "Add expense",
    "button.read_more": "ပိုမိုသိရှိရန်",
    "button.show_less": "အကျဉ်းချုံ့ပြရန်",
    "topnav.budget.h1": "ဘတ်ဂျက် ဂဏာန်းတွက်စက်",
    "topheader.income.h2": "ဝင်ငွေ",
    "topheader.income.p": "သင်၏ ဝင်ငွေရလမ်းများအားလုံးကို ဖြည့်ကြည့်ကြစို့",
    "topheader.expense.h2": "အသုံးစရိတ်များ",
    "topheader.expense.p":
      "‌အောက်တွင် သင်၏ အသုံးစရိတ်များကို ဖြည့်ပါ။ အကြွေးဝယ်ကတ်သုံးခြင်း၊ ချေးငွေများ၊ ပေးစရာများနှင့် အသုံးစရိတ်များအားလုံးကို ဖြည့်သွင်းပေးရမည်",
    "topheader.budget_summary.p":
      "သင်၏ ဘတ်ဂျက်အစီအစဉ် အောင်မြင်ပါသည်။ သင် ငွေမည်မျှစုဆောင်းနိုင်မည်ကို ကြည့်ကြပါစို့",
    "card.other_income.h4": "အခြားဝင်ငွေ",
    "card.other_income.p":
      "သင့်တွင် အခြားဝင်ငွေများ ရှိပါသလား။ ဉပမာ - အစိုးရထံမှ အကျိုးခံစားခွင့်များ၊ ဒုတိယဝင်ငွေရအလုပ်၊ အိမ်ငှားရမ်းခ၊ Grab မောင်းနှင်မှုမှ၊ ရှယ်ယာဝယ်ယူမှုမှ အစရှိသဖြင့်",
    "topnav.setting.h1": "ပြင်ဆင်ရန်",
    "label.language": "ဘာသာစကား",
    "label.currency": "‌ငွေကြေးအမျိုးအစား",
    "label.push_notification": "သတိပေးမှုများကို နှိပ်ပါ",
    "label.language.desc": "ဘာသာစကားရွေးချယ်ပါ",
    "label.currency.desc": "ငွေကြေးအမျိုးအစားကို ရွေးချယ်ပါ",
    "label.push_notification.desc": "အက်မှ သတိပေးမှုများကို ခွင့်ပြုပါသည်",
    "label.not_supported": "မပံ့ပိုးပါ",
    "button.view_all_operators": "၀န်ဆောင်မှုပေးသူများအားလုံးကို ကြည့်ရန်",
    "logo.supported.h3": "ပံ့ပိုးပေးသူများမှာ",
    "logo.managed.h3": "ကြီးကြပ်စီမံသူများမှာ",
    "label.view_legend": "ငွေလွှဲကန့်သတ်ချက် ကြည့်ရန်",
    "label.legend": "ငွေလွှဲကန့်သတ်ချက်",
    "menu.about": "အကြောင်းအရာ",
    "menu.privacy_policy": "ပုဂ္ဂိုလ်ရေးလွတ်လပ်ခွင့် မူဝါဒ",
    "menu.terms_and_conditions": "သတ်မှတ်ချက်များ",
    "menu.disclaimer": "ငြင်းဆိုချက်",
    "label.january": "ဇန်နဝါရီလ",
    "label.february": "‌ဖေဖေါ်ဝါရီလ",
    "label.march": "မတ်လ",
    "label.april": "ဧပြီလ",
    "label.may": "‌မေလ",
    "label.june": "ဇွန်လ",
    "label.july": "ဇူလိုင်လ",
    "label.august": "ဩဂုတ်လ",
    "label.september": "စက်တင်ဘာလ",
    "label.october": "‌အောက်တိုဘာလ",
    "label.november": "နိုဝင်ဘာလ",
    "label.december": "ဒီဇင်ဘာလ",
    "error.primary_income": "အဓိကဝင်ငွေ လိုအပ်သည်",
    "error.basic_wage": "အခြေခံဝင်ငွေ လိုအပ်သည်",
    "button.apply": "လျှောက်ထားပါ`",
    "button.clear": "ရှင်းလင်းသေ",
    "economic.hardship":
      "သင်ဤနှုန်းအတိုင်းဆက်သုံးစွဲလျှင်စီးပွားရေးအခက်အခဲဖြစ်နိုင်သည်။",
    "play.video": "ဗီဒီယိုဖွင့်သည်",
    "label.download": "ဒေါင်းလုပ်",
    "label.download_summary": "အကျဉ်းချုပ်ရယူပါ",
    "label.phone": "ဖုန်း",
    "label.fax": "ဖက်စ်",
    "label.email": "အီးမေးလ်",
    "label.website": "ဝဘ်ဆိုက်",
    "button.send_money": "ငွေပို့ပါ",
    "label.fx": "FX",
    "label.more_options_available": "လွှဲပြောင်းရန်ပိုမိုရွေးချယ်စရာများ",
    "label.send_method": "ပို့ရန်နည်းလမ်း",
    "label.receive_method": "လက်ခံနည်း",
    "button.more_details": "အသေးစိတ်များ",
    "label.no_additional_fees": "အပိုဆောင်းအခကြေးငွေများမရှိပါ",
    "label.additional_fee": "* အခကြေးငွေလက်ခံရယူနိုင်သည်",
    "onesignal.promptOptions.actionMessage":
      "យើង​ចង់​ផ្ញើ​ការ​ជូន​ដំណឹង​ជំរុញ​ឱ្យ​អ្នក​។ អ្នកអាចឈប់ជាវបានគ្រប់ពេល។",
    "onesignal.promptOptions.exampleNotificationTitleDesktop":
      "ការជូនដំណឹង SaverAsia",
    "onesignal.promptOptions.exampleNotificationMessageDesktop":
      "ការជូនដំណឹងនឹងបង្ហាញនៅលើកុំព្យូទ័ររបស់អ្នក។",
    "onesignal.promptOptions.exampleNotificationTitleMobile":
      "ការជូនដំណឹង SaverAsia",
    "onesignal.promptOptions.exampleNotificationMessageMobile":
      "ការជូនដំណឹងនឹងបង្ហាញនៅលើឧបករណ៍របស់អ្នក។",
    "onesignal.promptOptions.exampleNotificationCaption":
      "(អ្នកអាចឈប់ជាវបានគ្រប់ពេល)",
    "onesignal.promptOptions.acceptButton": "អនុញ្ញាត",
    "onesignal.promptOptions.acceptButtonText": "អនុញ្ញាត",
    "onesignal.promptOptions.cancelButton": "ရပါတယ်ကျေးဇူးပါ",
    "onesignal.promptOptions.cancelButtonText": "ရပါတယ်ကျေးဇူးပါ",
    "onesignal.welcomeNotification.title":
      "SaverAsia မှလှိုက်လှဲစွာကြိုဆိုပါသည်။",
    "onesignal.welcomeNotification.message":
      "စာရင်းသွင်းမှုအတွက် ကျေးဇူးတင်ပါသည်။",
    "onesignal.notifyButton.text.tipStateUnsubscribed":
      "အသိပေးချက်များကို စာရင်းသွင်းပါ။",
    "onesignal.notifyButton.text.tipStateSubscribed":
      "သင်သည် အကြောင်းကြားချက်များကို စာရင်းသွင်းထားသည်။",
    "onesignal.notifyButton.text.tipStateBlocked":
      "အကြောင်းကြားချက်များကို သင်ပိတ်ဆို့ထားသည်။",
    "onesignal.notifyButton.text.messageActionSubscribed":
      "စာရင်းသွင်းမှုအတွက် ကျေးဇူးတင်ပါသည်။",
    "onesignal.notifyButton.text.messageActionUnsubscribed":
      "အကြောင်းကြားချက်များ ထပ်မံရရှိမည်မဟုတ်ပါ။",
    "onesignal.notifyButton.text.dialogMainTitle":
      "ဆိုက် အသိပေးချက်များကို စီမံပါ။",
    "onesignal.notifyButton.text.dialogMainButtonSubscribe":
      "SUBSCRIBE လုပ်ပါ။",
    "onesignal.notifyButton.text.dialogMainButtonUnsubscribe":
      "စာရင်းသွင်းမှုဖြုတ်ပါ။",
    "onesignal.notifyButton.text.dialogBlockedTitle":
      "အကြောင်းကြားချက်များကို ပြန်ဖွင့်ပါ။",
    "onesignal.notifyButton.text.dialogBlockedMessage":
      "အကြောင်းကြားချက်များကို ခွင့်ပြုရန် ဤညွှန်ကြားချက်များကို လိုက်နာပါ-",
  },
  BN: {
    "topheader.compare.help": "সাহায্য দরকার? আমাদের ভিডিও গাইড দেখুন.",
    "carousel.item1.h2": "সময় বাঁচান",
    "carousel.item2.h2": "অর্থ সঞ্চয়",
    "carousel.item3.h2": "আপনার ভবিষ্যতের জন্য সঞ্চয় করুন",
    "carousel.item1.p": "বাড়িতে টাকা পাঠানোর সময় আপনাকে সেরা মূল্য এবং পণ্য খুঁজে পেতে সহায়তা করার জন্য মানি ট্রান্সফার প্রোভাইডারদের সঙ্গে দ্রুত তুলনা করে সময় বাঁচান।",
    "carousel.item2.p": "মানি ট্রান্সফার প্রোভাইডারদের তুলনা করে এবং SaverAsia ক্যালকুলেটর ব্যবহার করে কীভাবে অর্থ সাশ্রয় করবেন তা জেনে নিন",
    "carousel.item3.p": "আমাদের ফাইন্যান্স বেসিক গাইডের মাধ্যমে কীভাবে আপনার ভবিষ্যতের জন্য সঞ্চয় করবেন এবং আর্থিক উন্নতি করবেন তা জেনে নিন",
    "button.start": "শুরু করুন",
    "topnav.compare.form.h1": "রেট তুলনা করুন",
    "topheader.compare.form.h2": "অর্থ স্থানান্তরে সঞ্চয় করুন",
    "topheader.compare.form.p": "অর্থ স্থানান্তর পরিষেবা প্রদানকারী কোম্পানিগুলির খরচ তুলনা করুন",
    "label.country_from": "থেকে টাকা পাঠান",
    "label.country_to": "টাকা পাঠান",
    "label.how_much": "কত",
    "button.compare": "এবার রেট তুলনা করুন",
    "topheader.compare.list.h2": "তুলনার ফলাফল",
    "topheader.compare.list.green": "আজ থেকে ফলাফল লাইভ করা হয়েছে, {date_today}. ",
    "topheader.compare.list.amber": "{date_updated} তারিখে ফলাফল সংগ্রহ করা হয়েছে। ",
    "topheader.compare.list.red": "{date_updated} তারিখে রহস্য কেনাকাটার মাধ্যমে ফলাফল সংগ্রহ করা হয়েছে।",
    "topheader.compare.list.p": "SaverAsia দিয়ে {country_from} থেকে {country_to} তে টাকা পাঠান",
    "bottomheader.compare.list.p": "এই ফলাফলগুলিতে এই রেমিটেন্স করিডোরের জন্য উপলব্ধ সমস্ত পরিষেবা অন্তর্ভুক্ত নাও থাকতে পারে। উপরের তথ্যের মধ্যে সমস্ত জ্ঞাত খরচ অন্তর্ভুক্ত রয়েছে।",
    "topnav.resource.h1": "ফাইন্যান্স বেসিক",
    "topheader.resource.form.h2": "ফাইন্যান্স বেসিক",
    "topheader.resource.form.p": "আপনার অর্থ সঞ্চয় বা পাঠাতে সাহায্য করতে, অনুগ্রহ করে আমাদের ফাইন্যান্স বেসিক টিপস পড়ুন।",
    "topnav.calculators.h1": "ক্যালকুলেটর",
    "card1.calculators.h3": "ওভারটাইম পে ক্যালকুলেটর",
    "card1.calculators.p": "আপনি যদি মালয়েশিয়ায় কাজ করেন তবে আপনি এই ক্যালকুলেটরটি ব্যবহার করে ওভারটাইম কত বেতন পাবেন তা নির্ধারণ করতে পারেন।",
    "card2.calculators.h3": "বাজেট ক্যালকুলেটর",
    "card2.calculators.p": "আপনি কতটা সঞ্চয় করতে পারেন এবং আপনি অতিরিক্ত ব্যয় করছেন না তা নিশ্চিত করতে আপনার আয় এবং আপনার ব্যয় যোগ করুন।",
    "breadcrumbs.step": "{count} টির মধ্যে {step} ম ধাপ",
    "topnav.overtime.h1": "ওভারটাইম ক্যালকুলেটর",
    "topheader.overtime.h2": "মালয়েশিয়ান ওভারটাইম পে ক্যালকুলেটর",
    "topheader.overtime.p": "এই ক্যালকুলেটরটি আপনার কাজ করা ঘন্টা এবং সময়ের প্রয়োজনীয়তার সাথে মানক সরকারের উপর ভিত্তি করে আপনার ওভারটাইমের পরিমাণ নির্ধারণ করবে।",
    "label.hourly_rate": "আপনার ঘন্টার হার হল",
    "label.overtime_hours.h4": "অতিরিক্ত ঘন্টা",
    "label.overtime_hours.p": "এমপ্লয়মেন্ট অ্যাক্ট 1955 (মালয়েশিয়া) অনুসারে, কর্মচারীকে স্বাভাবিক কাজের সময়ের চেয়ে বেশি সময় ওভারটাইম কাজের জন্য তার ঘন্টায় 1.5 গুণের কম হারে বেতন দেওয়া হবে। বিশ্রামের দিনে কাজের জন্য বেতন ঘন্টার হারের 2.0 গুণের কম হবে না, এবং একটি সরকারি ছুটির দিনে ঘন্টার হারের 3.0 গুণের কম হবে না।",
    "label.basic_wage": "মৌলিক মজুরি",
    "label.pay_period": "পে পিরিয়ড",
    "label.amount": "পরিমাণ",
    "label.standard_days": "সাধারণ দিন (x1.5)",
    "label.rest_days": "বিশ্রামের দিন (x2)",
    "label.public_holidays": "সরকারি ছুটি (x3)",
    "label.paid_public_holidays": "প্রদত্ত সরকারি ছুটি (x2)",
    "label.unpaid_public_holidays": "অবৈতনিক সরকারি ছুটি (x3)",
    "label.overtime": "ওভারটাইম",
    "label.total_expense": "মোট খরচ",
    "label.total_income": "মোট আয়",
    "label.money_left": "টাকা বাকি",
    "label.daily": "দৈনিক",
    "label.weekly": "সাপ্তাহিক",
    "label.monthly": "মাসিক",
    "label.item": "আইটেম",
    "label.primary_income": "প্রাথমিক আয়",
    "label.market_rate": "লাইভ ডেটা ফলাফলের জন্য মিড মার্কেট FX হল {market_rate}",
    "label.operator": "অপারেটর",
    "label.type": "স্থানান্তরের ফর্ম",
    "label.receiving": "রিসিভিং",
    "label.rate": "FX রেট",
    "label.fee": "ফি",
    "label.form": "ফর্ম",
    "label.method": "স্থানান্তরের পদ্ধতি",
    "label.speed": "স্থানান্তরের গতি",
    "label.number_overtime": "ওভারটাইম কাজ করা ঘন্টার সংখ্যা",
    "topnav.faq.h1": "সচরাচর জিজ্ঞাস্য",
    "topnav.video.h1": "গাইড",
    "topheader.video.form.h2": "ভিডিও গাইড",
    "topheader.video.form.p": "SaverAsia অ্যাপ ফাংশনগুলি কীভাবে ব্যবহার করবেন তা বোঝার জন্য অনুগ্রহ করে আমাদের ভিডিও গাইডগুলি ব্যবহার করুন৷",
    "bottom.faq.p": 'SaverAsia-এর কাছে আপনার বেশিরভাগ প্রশ্নের উত্তর আছে কিন্তু আপনার যদি আরও তথ্যের প্রয়োজন হয়, অনুগ্রহ করে আমাদের সাথে <a href="enquiries@saver.global">enquiries@saver.global</a> এ যোগাযোগ করুন এবং আমরা আপনাকে সাহায্য করার চেষ্টা করব৷',
    "notfound.h2": '"ওহ না"',
    "notfound.p": "দুঃখিত, আপনি যে স্ক্রীনটি খুঁজছেন তা আমরা খুঁজে পাচ্ছি না। নীচের নেভিগেশন ব্যবহার করুন.",
    "topheader.summary.h2": "সারসংক্ষেপ",
    "topheader.summary.p": "নীচে আপনি এই সময়ের জন্য কত টাকা পাবেন তা দেখতে পারেন৷",
    "label.no_results": "আপনার সমস্ত ফিল্টার পদ সম্বলিত কোনো ফলাফল পাওয়া যায়নি।",
    "button.back": "পেছনে",
    "button.prev": "আগে",
    "button.next": "পরবর্তী",
    "button.remove": "অপসারণ",
    "button.add_income": "আয় যোগ করুন",
    "button.add_expense": "খরচ যোগ করুন",
    "button.read_more": "আরও পড়ুন",
    "button.show_less": "কম প্রদর্শন করুন",
    "topnav.budget.h1": "বাজেট ক্যালকুলেটর",
    "topheader.income.h2": "আয়",
    "topheader.income.p": "আসুন আপনার অর্থ উপার্জনের সমস্ত উপায় প্রবেশ করানো শুরু করি।",
    "topheader.expense.h2": "খরচ",
    "topheader.expense.p": "নীচে আপনার খরচ যোগ করুন. যেমন ভাড়া, খাবার, রেমিটেন্স, বিল (ফোন, বিদ্যুৎ ইত্যাদি) এবং ভ্রমণ।",
    "topheader.budget_summary.p": "আপনার বাজেট প্ল্যান সম্পূর্ণ করার জন্য ভাল হয়েছে, আসুন দেখি আপনি কত টাকা সঞ্চয় করেছেন।",
    "card.other_income.h4": "অন্যান্য আয়",
    "card.other_income.p": "আপনি কি অন্য কোন আয় উপার্জন করেন? যেমন সরকারি সুবিধা, দ্বিতীয় চাকরি, ভাড়া আয়, গ্র্যাব চালানো, শেয়ার ইত্যাদি।",
    "topnav.setting.h1": "সেটিংস",
    "label.language": "ভাষা",
    "label.currency": "মুদ্রা",
    "label.push_notification": "পুশ বিজ্ঞপ্তি",
    "label.language.desc": "অ্যাপের ভাষা নির্বাচন করুন",
    "label.currency.desc": "অ্যাপের মুদ্রা নির্বাচন করুন",
    "label.push_notification.desc": "অ্যাপকে বিজ্ঞপ্তি পুশ করার অনুমতি দিন",
    "label.not_supported": "সমর্থিত নয়",
    "button.view_all_operators": "সব অপারেটরদের দেখুন",
    "logo.supported.h3": "দ্বারা সমর্থিত",
    "logo.managed.h3": "দ্বারা পরিচালিত",
    "label.view_legend": "লেজেন্ডদের দেখুন",
    "label.legend": "কিংবদন্তি",
    "menu.about": "সম্পর্কিত",
    "menu.privacy_policy": "প্রাইভেসি পলিসি",
    "menu.terms_and_conditions": "শর্তাবলী",
    "menu.disclaimer": "দাবিত্যাগ",
    "label.january": "জানুয়ারি",
    "label.february": "ফেব্রুয়ারি",
    "label.march": "মার্চ",
    "label.april": "এপ্রিল",
    "label.may": "মে",
    "label.june": "জুন",
    "label.july": "জুলাই",
    "label.august": "আগস্ট",
    "label.september": "সেপ্টেম্বর",
    "label.october": "অক্টোবর",
    "label.november": "নভেম্বর",
    "label.december": "ডিসেম্বর",
    "error.primary_income": "প্রাথমিক আয় প্রয়োজন",
    "error.basic_wage": "বেসিক মজুরি প্রয়োজন",
    "button.apply": "অ্যাপ্লাই করুন",
    "button.clear": "পরিষ্কার করুন",
    "economic.hardship": "সম্ভাব্য অর্থনৈতিক সমস্যা দেখা দিতে পারে",
    "play.video": "ভিডিও প্লে করুন",
    "label.download": "ডাউনলোড করুন",
    "label.download_summary": "ডাউনলোড সারাংশ",
    "label.phone": "ফোন",
    "label.fax": "ফ্যাক্স",
    "label.email": "ইমেইল",
    "label.website": "ওয়েবসাইট",
    "button.send_money": "টাকা পাঠাও",
    "button.promo": "রচার",
    "button.details": "বিস্তারিত",
    "label.fx": "এফএক্স",
    "label.more_options_available": "আরো স্থানান্তর বিকল্প উপলব্ধ",
    "label.send_method": "পাঠানোর পদ্ধতি",
    "label.receive_method": "গ্রহণ করার পদ্ধতি ",
    "button.more_details": "আরো বিস্তারিত",
    "label.no_additional_fees": "কোন অতিরিক্ত ফি নেই",
    "label.additional_fee": "* প্রাপ্তি ফি প্রযোজ্য হতে পারে",
    "onesignal.promptOptions.actionMessage": "আমরা আপনাকে পুশ বিজ্ঞপ্তি পাঠাতে চাই। আপনি যে কোনো সময় ত্যাগ করতে পারেন।",
    "onesignal.promptOptions.exampleNotificationTitleDesktop": "SaverAsia বিজ্ঞপ্তি",
    "onesignal.promptOptions.exampleNotificationMessageDesktop": "বিজ্ঞপ্তিটি আপনার ডেস্কটপে প্রদর্শিত হবে",
    "onesignal.promptOptions.exampleNotificationTitleMobile": "SaverAsia বিজ্ঞপ্তি",
    "onesignal.promptOptions.exampleNotificationMessageMobile": "বিজ্ঞপ্তিটি আপনার ডিভাইসে প্রদর্শিত হবে",
    "onesignal.promptOptions.exampleNotificationCaption": "(আপনি যে কোনো সময় আনসাব্সক্রাইব করতে পারেন)",
    "onesignal.promptOptions.acceptButton": "অনুমতি দিন",
    "onesignal.promptOptions.acceptButtonText": "অনুমতি দিন",
    "onesignal.promptOptions.cancelButton": "না ধন্যবাদ",
    "onesignal.promptOptions.cancelButtonText": "না ধন্যবাদ",
    "onesignal.welcomeNotification.title": " SaverAsia-তে স্বাগতম",
    "onesignal.welcomeNotification.message": "সাবস্ক্রাইব করার জন্য ধন্যবাদ!",
    "onesignal.notifyButton.text.tipStateUnsubscribed": "বিজ্ঞপ্তি সাবস্ক্রাইব করুন",
    "onesignal.notifyButton.text.tipStateSubscribed": "আপনি বিজ্ঞপ্তিতে সদস্যতা নিয়েছেন",
    "onesignal.notifyButton.text.tipStateBlocked": "আপনি বিজ্ঞপ্তি ব্লক করেছেন",
    "onesignal.notifyButton.text.messageActionSubscribed": "সাবস্ক্রাইব করার জন্য ধন্যবাদ!",
    "onesignal.notifyButton.text.messageActionUnsubscribed": "আপনি আর বিজ্ঞপ্তি পাবেন না",
    "onesignal.notifyButton.text.dialogMainTitle": "সাইট বিজ্ঞপ্তি পরিচালনা করুন",
    "onesignal.notifyButton.text.dialogMainButtonSubscribe": "সাবস্ক্রাইব",
    "onesignal.notifyButton.text.dialogMainButtonUnsubscribe": "আনসাবস্ক্রাইব করুন",
    "onesignal.notifyButton.text.dialogBlockedTitle": "বিজ্ঞপ্তি আনব্লক করুন",
    "onesignal.notifyButton.text.dialogBlockedMessage": "বিজ্ঞপ্তির অনুমতি দিতে এই নির্দেশাবলী অনুসরণ করুন:",
  },
  NE: {
    "topheader.compare.help": "सहयोग चाहिएको हो? हाम्रा भिडियो गाइडहरू जाँच गर्नुहोस्।",
    "carousel.item1.h2": "समयको बचत गर्नुहोस्",
    "carousel.item2.h2": "पैसा बचत गर्नुहोस्",
    "carousel.item3.h2": "आफ्नो भविष्यका लागि पैसा बचाउनुहोस्",
    "carousel.item1.p": "तपाईंले घरमा पैसा पठाउँदा उत्कृष्ट मूल्य र उत्पादन फेला पार्न मद्दत पाउनमा मनि ट्रान्स्फर प्रदायकहरूलाई द्रुत रूपमा तुलना गरेर समय बचाउनुहोस्।",
    "carousel.item2.p": "मनि ट्रान्स्फर प्रदायकहरूलाई तुलना गरेर र सेभर एशिया क्याल्कुलेटरहरू प्रयोग गरेर पैसा बचाउने तरिका जान्नुहोस्",
    "carousel.item3.p": "आफ्नो भविष्यका लागि पैसा कसरी बचाउने र हाम्रो वित्तीय आधारहरूका गाइडमार्फत आफ्नो वित्तीय सुधार कसरी गर्ने भनी जान्नुहोस्",
    "button.start": "सुरु गर्नुहोस्",
    "topnav.compare.form.h1": "दरहरू तुलना गर्नुहोस्",
    "topheader.compare.form.h2": "मनि ट्रान्स्फरहरूमा बचत गर्नुहोस्",
    "topheader.compare.form.p": "मनि ट्रान्स्फर सेवाहरू प्रदान गर्ने कम्पनीहरूको मूल्यहरू तुलना गर्नुहोस् ",
    "label.country_from": "देशबाट पैसा पठाउनुहोस्",
    "label.country_to": "देशमा पैसा पठाउनुहोस्",
    "label.how_much": "कति",
    "button.compare": "अहिले नै दरहरू तुलना गर्नुहोस्",
    "topheader.compare.list.h2": "तुलना परिणामहरु",
    "topheader.compare.list.green": "आजका ताजा परिणामहरु, {date_today}। ",
    "topheader.compare.list.amber": "नतिजाहरू {date_updated} मा सङ्कलन गरिएको थियो ",
    "topheader.compare.list.red": "{date_updated} मा सङ्कलन गरिएका परिणामहरु।",
    "topheader.compare.list.p": "सेभर एशियाबाट {country_from} बाट {country_to} मा पैसा पठाउनुहोस्",
    "bottomheader.compare.list.p": "यी परिणामहरूले यस रेमिटेन्स करिडोरका लागि उपलब्ध सबै सेवाहरू समावेश नगर्न सक्छन्। माथिको जानकारीले सबै ज्ञात लागतहरू समावेश गर्दछ।",
    "topnav.resource.h1": "वित्तीय आधारहरू",
    "topheader.resource.form.h2": "वित्तीय आधारहरू",
    "topheader.resource.form.p": "तपाईंको पैसा बचत गर्न वा आफ्नो पैसा पठाउन मद्दतका लागि कृपया हाम्रो वित्तीय आधारहरूसम्बन्धी सुझावहरू पढ्नुहोस्।",
    "topnav.calculators.h1": "क्याल्कुलेटर",
    "card1.calculators.h3": "ओभरटाइम पे क्याल्कुलेटर",
    "card1.calculators.p": "तपाईं मलेसियामा काम गर्दै हुनुहुन्छ भने तपाईंले यो क्याल्कुलेटर आफुले ओभरटाइम पे कति प्राप्त गर्नु पर्ने थाहा पाउनलाई प्रयोग गर्न सक्नुहुन्छ।",
    "card2.calculators.h3": "बजेट क्याल्कुलेटर",
    "card2.calculators.p": "तपाईंले कति बचाउन सक्नुहुन्छ र आफूले अत्याधिक खर्च गरेको छैन भन्ने सुनिश्चित गर्न तपाईंको आम्दानी र खर्चहरू थप्नुहोस्।",
    "breadcrumbs.step": "चरण {count} को {step}",
    "topnav.overtime.h1": "ओभरटाइम क्याल्कुलेटर",
    "topheader.overtime.h2": "मलेसियाली ओभरटाइमको भुक्तानीसम्बन्धी क्याल्कुलेटर",
    "topheader.overtime.p": "यो क्याल्कुलेटरले तपाईंले काम गरेको घण्टा र समयको आवश्यकताअनुसार मानक सरकारको आधारमा तपाईंले प्राप्त गर्नु पर्ने ओभरटाइमको रकम वर्क आउट गर्ने छ।",
    "label.hourly_rate": "तपाईंको घण्टाको दर भनेको ",
    "label.overtime_hours.h4": "ओभरटाइम घण्टा हो",
    "label.overtime_hours.p": "रोजगार ऐन १९५५ (मलेसिया) काअनुसार, कर्मचारीलाई ओभरटाइम कामका लागि सामान्य काम गर्ने घण्टाभन्दा बढी भुक्तानी हुने गरी उहाँको घण्टाको १.५ गुणाभन्दा कम नहुने दरमा भुक्तानी गरिने छ। विश्रामको दिनमा कामको तलब प्रति घण्टा दरको २.० गुणा भन्दा कम हुनु हुँदैन र सार्वजनिक बिदामा घण्टाको दरको ३.० गुणा भन्दा कम हुँदैन।",
    "label.basic_wage": "आधारभूत तलब",
    "label.pay_period": "भुक्तानी अवधि",
    "label.amount": "रकम",
    "label.standard_days": "सामान्य दिनहरू (x१.५)",
    "label.rest_days": "विश्राम गर्ने दिनहरू (x२)",
    "label.public_holidays": "सार्वजनिक बिदाहरू (x३)",
    "label.paid_public_holidays": "सार्वजनिक तलबी बिदाहरू (x२)",
    "label.unpaid_public_holidays": "सार्वजनिक बेतलबी बिदाहरू (x३)",
    "label.overtime": "ओभरटाइम",
    "label.total_expense": "कुल खर्चहरू",
    "label.total_income": "कुल आम्दानी",
    "label.money_left": "छोडिएको पैसा",
    "label.daily": "दैनिक",
    "label.weekly": "साप्ताहिक",
    "label.monthly": "मासिक",
    "label.item": "वस्तु",
    "label.primary_income": "प्राथमिक आम्दानी",
    "label.market_rate": "प्रतक्ष्य नतिजाहरूका लागि मध्य बजार विदेशी विनिमय {market_rate} हो।",
    "label.operator": "सञ्चालक",
    "label.type": "स्थानान्तरण फारम",
    "label.receiving": "प्राप्त गर्दै",
    "label.rate": "विदेशी विनिमय दर",
    "label.fee": "शुल्क",
    "label.form": "फारम",
    "label.method": "स्थानान्तरण विधि",
    "label.speed": "ट्रान्सफर को गति",
    "label.number_overtime": "ओभरटाइम काम गरेको सङ्ख्या",
    "topnav.faq.h1": "बारम्बार सोधिने प्रश्नहरू",
    "topnav.video.h1": "गाइडहरू",
    "topheader.video.form.h2": "भिडियोको गाइडहरू",
    "topheader.video.form.p": "कृपया सेभर एशिया एपको क्षमताहरू कसरी प्रयोग गरिन्छ भन्नेबारे बुझ्नका लागि तपाईंलाई मद्दत गर्न हाम्रो भिडियो गाइडहरू प्रयोग गर्नुहोस् ",
    "bottom.faq.p": "सेभर एशियासँग तपाईंका धेरैजसो प्रश्नहरूको जवाफ छ तर यदि तपाईंलाई अधिक जानकारी चाहिन्छ भने, कृपया हामीलाई <a>enquiries@saver.global</a> मा सम्पर्क गर्नुहोस् र हामी तपाईंलाई मद्दत गर्ने प्रयास गर्नेछौं।",
    "notfound.h2": 'ओह - उ',
    "notfound.p": "माफ गर्नुहोस्, तपाईंले खोज्नु भएको स्क्रिन हामीले फेला पार्न सकेनौं। तलको नेभिगेसन प्रयोग गर्नुहोस्।",
    "topheader.summary.h2": "सारांश",
    "topheader.summary.p": "तपाईंले यो अवधिमा कति पैसा प्राप्त गर्नु पर्ने हुन्छ भनेर तल हेर्न सक्नुहुन्छ।",
    "label.no_results": "तपाइँका सबै फिल्टर शब्दहरु समावेश गर्ने कुनै नतिजा फेला परेन।",
    "button.back": "पछाडि जानुहोस्",
    "button.prev": "अघिल्लो",
    "button.next": "अर्को",
    "button.remove": "हटाउनुहोस्",
    "button.add_income": "आम्दानी थप्नुहोस्",
    "button.add_expense": "खर्च थप्नुहोस्",
    "button.read_more": "थप पढ्नुहोस्",
    "button.show_less": "कम देखाउनुहोस्",
    "topnav.budget.h1": "बजेट क्याल्कुलेटर",
    "topheader.income.h2": "आम्दानी",
    "topheader.income.p": "यसमा तपाईंले कमाउने आम्दानीका सबै स्रोत हाल्नुहोस्।",
    "topheader.expense.h2": "खर्च",
    "topheader.expense.p": "तपाईंको खर्च तल थप्नुहोस्। उदाहरण भाडा, खाद्य, रेमिट्यान्स, बिल (फोन, बिजुलीको बिल आदि) र यातायात।",
    "topheader.budget_summary.p": "तपाईलाई बजेट योजना पूरा गर्नमा बधाई छ, हेरौं तपाईंले कति पैसा बचत गर्नुभयो।",
    "card.other_income.h4": "अन्य आम्दानी",
    "card.other_income.p": "तपाईंले अन्य स्रोतबाट आम्दानी गर्नुहुन्छ? उदाहरण सरकारी लाभ, सहायक जागिर, भाडाको आम्दानी, गाडी चलाउने, सेयरहरू आदि।",
    "topnav.setting.h1": "सेटिङहरू",
    "label.language": "भाषा",
    "label.currency": "मुद्रा",
    "label.push_notification": "पुश सूचनाहरू",
    "label.language.desc": "एपको भाषा चयन गर्नुहोस्",
    "label.currency.desc": "एपको मुद्रा चयन गर्नुहोस्",
    "label.push_notification.desc": "एपलाई सूचना पुश गर्न अनुमति दिनुहोस्",
    "label.not_supported": "समर्थित छैन",
    "button.view_all_operators": "सबै अपरेटरहरू हेर्नुहोस्",
    "logo.supported.h3": "समर्थित द्वारा",
    "logo.managed.h3": "व्यवस्थित द्वारा",
    "label.view_legend": "लिजेन्ड हेर्नुहोस्",
    "label.legend": "सङ्केत",
    "menu.about": "बारेमा",
    "menu.privacy_policy": "गोपनीयता नीति",
    "menu.terms_and_conditions": "नियम तथा सर्तहरू",
    "menu.disclaimer": "अस्वीकरण",
    "label.january": "जनवरी",
    "label.february": "फेब्रुअरी",
    "label.march": "मार्च",
    "label.april": "अप्रिल",
    "label.may": "मे",
    "label.june": "जुन",
    "label.july": "जुलाई",
    "label.august": "अगस्ट",
    "label.september": "सेप्टेम्बर",
    "label.october": "अक्टोबर",
    "label.november": "नोभेम्बर",
    "label.december": "डिसेम्बर",
    "error.primary_income": "प्राथमिक आम्दानी आवश्यक छ",
    "error.basic_wage": "आधारभूत तलब आवश्यक छ",
    "button.apply": "लागू गर्नुहोस्",
    "button.clear": "खाली गर्नुहोस्",
    "economic.hardship": "यो दरमा खर्च गरिरहँदा सम्भावित आर्थिक कठिनाइ आउन सक्छ।",
    "play.video": "भिडियो प्ले गर्नुहोस्",
    "label.download": "डाउनलोड गर्नुहोस्",
    "label.download_summary": "सारांश डाउनलोड गर्नुहोस्",
    "label.phone": "फोन",
    "label.fax": "फ्याक्स",
    "label.email": "इमेल",
    "label.website": "वेबसाईट",
    "button.send_money": "पैसा पठाउनु",
    "button.promo": "प्रोमो",
    "button.details": "विवरणहरू",
    "label.fx": "विदेशी विनिमय",
    "label.more_options_available": "अधिक स्थानान्तरण विकल्पहरू उपलब्ध छन्",
    "label.send_method": "पठाउने विधि",
    "label.receive_method": "प्राप्त विधि",
    "button.more_details": "थप विवरणहरू",
    "label.no_additional_fees": "कुनै अतिरिक्त शुल्क छैन",
    "label.additional_fee": "* प्राप्ति शुल्क लागू हुन सक्छ",
    "onesignal.promptOptions.actionMessage": "हामी तपाईंलाई पुश सूचनाहरू पठाउन चाहन्छौँ। तपाईं जुनसुकै समयमा सदस्यता रद्द गर्न सक्नुहुन्छ ।",
    "onesignal.promptOptions.exampleNotificationTitleDesktop": "सेभर एशिया सूचना",
    "onesignal.promptOptions.exampleNotificationMessageDesktop": "सूचनाहरू तपाईंको डेस्कटपमा आउने छ।",
    "onesignal.promptOptions.exampleNotificationTitleMobile": "सेभर एशिया सूचना",
    "onesignal.promptOptions.exampleNotificationMessageMobile": "सूचनाहरू तपाईंको डिभाइसमा आउने छ।",
    "onesignal.promptOptions.exampleNotificationCaption": "(तपाईं जुनसुकै बेला सदस्यता रद्द गर्न सक्नुहुन्छ)",
    "onesignal.promptOptions.acceptButton": "अनुमति दिनुहोस्",
    "onesignal.promptOptions.acceptButtonText": "अनुमति दिनुहोस्",
    "onesignal.promptOptions.cancelButton": "पर्दैन, धन्यवाद",
    "onesignal.promptOptions.cancelButtonText": "पर्दैन, धन्यवाद",
    "onesignal.welcomeNotification.title": "सेभर एशियामा स्वागत छ",
    "onesignal.welcomeNotification.message": "सदस्यता लिनुभएकोमा धन्यवाद!",
    "onesignal.notifyButton.text.tipStateUnsubscribed": "सूचनाहरूका लागि सदस्य बन्नुहोस्",
    "onesignal.notifyButton.text.tipStateSubscribed": "तपाईं सूचनाहरूका लागि सदस्य बन्नु भएको छ",
    "onesignal.notifyButton.text.tipStateBlocked": "तपाईंले सूचनाहरू ब्लक गर्नुभएको छ ।",
    "onesignal.notifyButton.text.messageActionSubscribed": "सदस्यता लिनुभएकोमा धन्यवाद!",
    "onesignal.notifyButton.text.messageActionUnsubscribed": "तपाईंले फेरी सूचनाहरू प्राप्त गर्नु हुने छैन",
    "onesignal.notifyButton.text.dialogMainTitle": "साइटका सूचनाहरू व्यवस्थित गर्नुहोस्",
    "onesignal.notifyButton.text.dialogMainButtonSubscribe": "सदस्यता लिनुहोस्",
    "onesignal.notifyButton.text.dialogMainButtonUnsubscribe": "सदस्यता रद्द गर्नुहोस्",
    "onesignal.notifyButton.text.dialogBlockedTitle": "सूचनाहरू अनब्लक गर्नुहोस्",
    "onesignal.notifyButton.text.dialogBlockedMessage": "सूचनाहरूको अनुमतिका लागि यी निर्देशनहरू पालना गर्नुहोस्ः",
  }
};
