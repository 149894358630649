import React from "react";
import { FormattedMessage } from "react-intl";
import Operator from "./Operator";

function OperatorList({ operators }) {
  const isTableVisible = operators.length > 0 ? true : false;

  return (
    <React.Fragment>
      {isTableVisible && <Table operators={operators} />}
      {!isTableVisible && (
        <div className="no-results">
          <FormattedMessage id={"label.no_results"} />
        </div>
      )}
    </React.Fragment>
  );
}

function Table({ operators }) {
  const operatorElements = operators.map((operator) => {
    return <Operator key={operator.CompanyId} {...operator} />;
  });
  return (
    <div className="table-scroll">
      <table className="comparison-table">
        <tbody>{operatorElements}</tbody>
      </table>
    </div>
  );
}

export default OperatorList;
