import React, { useContext } from 'react'
import { FormattedMessage } from "react-intl"
import { Card, CardContent } from './../utils/Card'
import CompareSelect from './CompareSelect'
import { CompareFormContext } from './index'

export default function CountryToSelect() {
	const {
		countryToOptions,
		countryTo,
		handleCountryToChange
	} = useContext(CompareFormContext)

	return (
		<Card className={"card--white"}>
        	{countryToOptions && <CardContent>
				<div className="form-group">
					<label className="label-control" htmlFor="country_to">
						<FormattedMessage id="label.country_to" />
					</label>
					{countryTo && <CompareSelect
										name="country_to"
										current={countryTo}
										options={countryToOptions}
										handleChange={handleCountryToChange}
									/>}
				</div>
			</CardContent>}
		</Card>
	)
}