import React, { Component } from 'react';
import {useIntl, injectIntl, FormattedMessage} from 'react-intl';

class AmberDot extends Component {
	  render() {
		     const { formatMessage } = this.props.intl;
		     return(<span className="amber-dot-desc">{formatMessage({ id: "dot.amber"})}</span>)
		    }
}
export default injectIntl(AmberDot);
