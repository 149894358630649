import React, { useState, useEffect } from 'react';
import Toast from 'toasted-notes';
import TopHeader from './TopHeader';
import OvertimeStep1 from './OvertimeStep1';
import OvertimeStep2 from './OvertimeStep2';
import BottomNavigation from './../navigation/BottomNavigation';
import BottomPagination from './../navigation/BottomPagination';
import { injectIntl } from 'react-intl';
import uuidv4 from 'uuid/v4'
//import google from 'react-ga';
//google.initialize('UA-151343945-1');
//google.pageview(window.location.pathname + window.location.search);

export const OvertimeContext = React.createContext()

const INCOME_KEY = 'saverasia.income'
const OVERTIME_KEY = 'saverasia.overtime'

function Index({ intl }) {
	const [income, setIncome] = useState([])
	const [overtime, setOvertime] = useState([])
	const [currentStep, setCurrentstep] = useState(1)

	useEffect(() => {		
		const incomeJSON = localStorage.getItem(INCOME_KEY)
		if (incomeJSON != null) {
			setIncome(JSON.parse(incomeJSON))
		} else {
			setIncome(initialIncome)
		}		

		const overtimeJSON = localStorage.getItem(OVERTIME_KEY)
		if (overtimeJSON != null) {
			setOvertime(JSON.parse(overtimeJSON))
		} else {
			setOvertime(initialOvertime)
		}
	}, [])

	useEffect(() => {
		!Array.isArray(income) && localStorage.setItem(INCOME_KEY, JSON.stringify(income))
	}, [income])

	useEffect(() => {
		overtime.length && localStorage.setItem(OVERTIME_KEY, JSON.stringify(overtime))
	}, [overtime])

	const budgetContextValue = {
		handleIncomeChange,
		handleOvertimeChange,
		income,
		overtime
	}

	function handleIncomeChange(income) {
		var hourly_rate = getHourlyRate(income)
		setIncome({ ...income, ...hourly_rate })
	}

	function handleOvertimeChange(id, newValue) {
		const newOvertime = [...overtime]
		const index = newOvertime.findIndex(i => i.id === id)
		newOvertime[index] = newValue
		setOvertime(newOvertime)
	}

	function getHourlyRate(income) {
        let hourly_rate = 0

        if (income.amount > 0) {
            switch (income.pay_period) {
                case 1:
                    hourly_rate = income.amount/8
                break;
                case 7:
                    hourly_rate = (income.amount/6)/8
                break;
                case 30:
                    hourly_rate = (income.amount/26)/8
                break;
                default:
                    hourly_rate = 0;
                break;
            }
        }

        return { hourly_rate: hourly_rate }
    }

	function handleCurrentStep(newStep) {
		if (income.amount > 0) {
			setCurrentstep(prevCurrentStep => {
				return prevCurrentStep + newStep
			})	

			return true
		}	

		Toast.notify(intl.messages["error.basic_wage"], {
			position: 'bottom-left',
			duration: 3000
		})

		return true
	}

	return (
		<div className="screen-container pagination">
			<OvertimeContext.Provider value={budgetContextValue}>
				<TopHeader
					currentStep={currentStep}
					totalSteps={2}
				/>
				{currentStep === 1 && <OvertimeStep1 />}
				{currentStep === 2 && <OvertimeStep2 />}
				<BottomPagination
					currentStep={currentStep}
					totalSteps={2}
					handleCurrentStep={handleCurrentStep}
				/>
				<BottomNavigation />
			</OvertimeContext.Provider>
		</div>
	)
}

const initialIncome = {
    amount: 0,
    pay_period: 1,
    hourly_rate: 0
}

const initialOvertime = [
	{
		id: uuidv4(),
	    name: 'standard_days',
		hours: 0,
		color: '#FF751A',
	    penalty: 1.5
	},
	{
		id: uuidv4(),
	    name: 'rest_days',
		hours: 0,
		color: '#8ae1fc',
	    penalty: 2
	},
	{
		id: uuidv4(),
	    name: 'paid_public_holidays',
		hours: 0,
		color: '#7161ef',
	    penalty: 2
	},
	{
		id: uuidv4(),
	    name: 'unpaid_public_holidays',
		hours: 0,
		color: '#0b4f6c',
	    penalty: 3
	}
]

export default injectIntl(Index);
