import React from 'react'
import Icon from './../utils/Icon'
import { FormattedMessage } from "react-intl";

export default function MarketRate({ marketRate }) {
    const mid_market_rate = new Intl.NumberFormat('en-EN', { minimumFractionDigits: 4, maximumFractionDigits: 4 }).format( marketRate.rate );
    
	return (
		<div className="label label--yellow">
            <Icon icon={"warning"} />
            <FormattedMessage id="label.market_rate" values={{ market_rate: mid_market_rate }} />
        </div>
	)
}