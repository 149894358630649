import React, { useContext } from 'react'
import { Card, CardContent } from './../utils/Card'
import PayPeriodSelect from './../form/PayPeriodSelect'
import HourlyIncome from './HourlyIncome'
import { FormattedMessage } from "react-intl"
import { OvertimeContext } from './index'

export default function PrimaryIncome() {
	const { handleIncomeChange, income } = useContext(OvertimeContext)

	const {
		amount,
		pay_period,
		hourly_rate
	} = income

	function handleChange(name, value) {
		let changes = { [name]: parseFloat(value) }
		handleIncomeChange({ ...income, ...changes })
	}

	return (
		<React.Fragment>
			<Card className={"card--white"}>
				<CardContent>
					<h3><FormattedMessage id="label.basic_wage" /></h3>
					<div className="form-row">
						<div className="form-group">
							<label className="label-control" htmlFor="amount">
								<FormattedMessage id="label.amount" />
							</label>
							<div className="input-group">
								<span className="input-currency">MYR</span>
								<input
									className="form-control"
									id="amount"
									name="amount"
									type="number"
									min="0"
									step="0.05"
									value={amount}
									onChange={e => handleChange('amount', e.target.value)}
								/>
							</div>
						</div>
						<div className="form-group">
							<label className="label-control" htmlFor="basic-wage">
								<FormattedMessage id="label.pay_period" />
							</label>
							{pay_period && <PayPeriodSelect
								name="pay_period"
								value={pay_period}
								onChange={handleChange}
							/>}
						</div>
					</div>
				</CardContent>
			</Card>
			<HourlyIncome hourly_rate={hourly_rate} />
		</React.Fragment>
	)
}