import React, { Component } from 'react';
import {useIntl, injectIntl, FormattedMessage} from 'react-intl';

class RedDot extends Component {
	  render() {
		     const { formatMessage } = this.props.intl;
		     return(<span className="red-dot-desc">{formatMessage({ id: "dot.red"})}</span>)
		    }
}
export default injectIntl(RedDot);
