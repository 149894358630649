import React, { useState } from 'react';
import  { Redirect } from 'react-router-dom'
import Logo from './../utils/LogoWhite';
import SaverAsiaLogo from "../../assets/img/saverasia-logo.svg";
//import google from 'react-ga';
//google.initialize('UA-151343945-1');
//google.pageview(window.location.pathname + window.location.search);

export default function Splash() {
    const [loading, setLoading] = useState(true)

    setTimeout(() => {
        setLoading(false)
    }, 2000)

    return (
        <div className="screen-container splash">
			<div className="container">
                <div className="screen-content">
                	<div className="screen-content--center">
                        <img src={SaverAsiaLogo} alt="SaverAsia" />
                    </div>
                	<Logo />
				</div>
			</div>
            {!loading && <Redirect to='/home' />}
		</div>
    )
}
