import React from 'react'
import { FormattedMessage, FormattedNumber } from "react-intl";
import Icon from '../utils/Icon'

export default function PrimaryIncome({ hourly_rate }) {
	return (
		<React.Fragment>
			<div className="label label--green">
                <Icon icon={"note"} />
                <FormattedMessage id="label.hourly_rate" />
                <FormattedNumber
					value={hourly_rate}
					style="currency"
    				currency="MYR"
    			/>
            </div>
		</React.Fragment>
	)
}