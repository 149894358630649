import React from 'react'
import { FormattedMessage } from "react-intl";
import Slider from "react-slick";
import SaveTimeImg from "../../assets/img/savetime.svg";
import SaveMoneyImg from "../../assets/img/savemoney.svg";
import SaveForYourFutureImg from "../../assets/img/saveforyourfuture.svg";

export default function SlickCarousel() {
	var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

	return (
		<div className="slick-carousel">
            <Slider {...settings}>
                <div className="carousel-item">
                    <img src={SaveTimeImg} alt="" />
                    <h2><FormattedMessage id="carousel.item1.h2" /></h2>
                    <p><FormattedMessage id="carousel.item1.p" /></p>
                </div>
                <div className="carousel-item">
                    <img src={SaveMoneyImg} alt="" />
                    <h2><FormattedMessage id="carousel.item2.h2" /></h2>
                    <p><FormattedMessage id="carousel.item2.p" /></p>
                </div>
                <div className="carousel-item">
                    <img src={SaveForYourFutureImg} alt="" />
                    <h2><FormattedMessage id="carousel.item3.h2" /></h2>
                    <p><FormattedMessage id="carousel.item3.p" /></p>
                </div>
            </Slider>
    	</div>
	)
}
