import React, { useEffect, useState, useContext } from 'react';
import TopNav from './../navigation/TopNav';
import TopHeader from './../navigation/TopHeader';
import { FormattedMessage } from "react-intl";
import BottomNavigation from './../navigation/BottomNavigation';
import Accordion from './../utils/Accordion';
import Loader from './../utils/Loader';
import { AppContext } from './../App'
import endpoint from '../../api';
//import google from 'react-ga';
//google.initialize('UA-151343945-1');
//google.pageview(window.location.pathname + window.location.search);

export default function Resource() {
    const { selectedLanguage, selectedSite } = useContext(AppContext)

    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
		endpoint.get(`/${selectedSite}/${selectedLanguage.value}/resource/`).then(response => {
            let resource = response.data
            endpoint.get(`/${selectedSite}/${selectedLanguage.value}/faq/`).then(response => {
                let faq = response.data
                setData([...resource, ...faq])
                setLoading(false)
            })
        })
    }, [selectedLanguage.value])
    
    return (
        <div className="screen-container">
            <TopNav>
                <h1><FormattedMessage id="topnav.resource.h1" /></h1>
            </TopNav>
	    <TopHeader>
                <h2><FormattedMessage id="topheader.resource.form.h2" /></h2>
                <p><FormattedMessage id="topheader.resource.form.p" /></p>
            </TopHeader>
            <div className="screen-content">
                <div className="container">
                    {loading ?
                        <Loader />
                            :
                        data.map((item, index) => (
                            <Accordion key={index} {...item} />
                        ))
                    }
                </div>
            </div>
            <BottomNavigation />
        </div>
    );
}
