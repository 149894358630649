import React from 'react'
import Icon from './../utils/Icon'
import Filter from './Filter'

function FilterList({ filters }) {
	const filterElements = filters.map(filter => {
		return <Filter key={filter.name} {...filter} />
	})
	return (
		<div className="filters">
      		{filterElements}
        </div>
	)
}

export default FilterList;
