import React from 'react'
import { FormattedMessage, FormattedNumber } from "react-intl";

export default function SummaryTotal(totalAmount) {
	return (
		<React.Fragment>
			<div className="table-row legend-total-income">
	            <div className="column">
	                <span className="legend-table-icon" style={{backgroundColor: totalAmount.color, borderColor: totalAmount.color}}></span>
	                <FormattedMessage id={totalAmount.label} />
	            </div>
	            <div className="column">
	            	<span style={{color: totalAmount.color}}>
		            	<FormattedNumber
							value={totalAmount.amount}
							style="currency"
		    				currency="MYR"
		    			/>
	            	</span>
	            </div>
			</div>
		</React.Fragment>
	)
}