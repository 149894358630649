import React, { useContext } from 'react'
import { FormattedMessage, FormattedNumber } from "react-intl";
import { AppContext } from './../App'

export default function Summary(props) {
	const { currency } = useContext(AppContext)

	const {
		label,
		color,
		amount
	} = props

	return (
		<div className="table-row">
            <div className="column">
                <span className="legend-table-icon" style={{backgroundColor: color, borderColor: color}}></span>
                <FormattedMessage id={label} />
            </div>
            <div className="column">
            	<FormattedNumber
					value={amount}
					style="currency"
    				currency={currency}
    			/>
            </div>
        </div>
	)
}