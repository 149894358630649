import React, { useState, useContext } from "react";
import { OvertimeContext } from "./index";
import SummaryPieChart from "./SummaryPieChart";
import Summary from "./Summary";
import SummaryTotal from "./SummaryTotal";
import { Card, CardContent } from "./../utils/Card";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { FormattedMessage } from "react-intl";

function SummaryList() {
  const { income, overtime } = useContext(OvertimeContext);

  const [overtimeList] = useState(overtime.filter((item) => item.hours !== 0));

  const [totalIncomes] = useState(() => {
    return {
      label: "label.basic_wage",
      color: "#00AB00",
      amount: income.amount,
    };
  });

  const [totalAmount] = useState(() => {
    return {
      label: "label.total_income",
      color: "",
      amount: parseFloat(income.amount + getTotalOvertime()),
    };
  });

  function getTotalOvertime() {
    let amount = 0;

    overtime.map((item) => {
      amount += parseFloat(
        (isNaN(item.hours) ? 0 : item.hours) * item.penalty * income.hourly_rate
      );
    });

    return amount;
  }

  let summaryList = [totalIncomes];

  const overtimeSummaryElements = overtimeList.map((item) => {
    let summary = {
      label: "label." + item.name,
      color: item.color,
      amount: parseFloat(
        (isNaN(item.hours) ? 0 : item.hours) * item.penalty * income.hourly_rate
      ),
    };

    if (summary.amount > 0) {
      summaryList.push(summary);

      return <Summary key={item.id} {...summary} />;
    }

    return false;
  });

  function printDocument() {
    const input = document.getElementById("divToPrint");
    const options = {
      scrollX: 0,
      scrollY: 0,
      allowTaint: true,
      useCORS: true,
      width: 793,
      height: 1122,
      windowHeight: window.outerHeight + window.innerHeight,
    };
    html2canvas(input, options).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpg");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "JPEG", 0, 0, 210, 297);
      pdf.save("overtime.pdf");
    });
  }

  return (
    <div>
      <div id="divToPrint">
        <Card className={"card--white"}>
          <SummaryPieChart list={summaryList} />
          <CardContent>
            <div className="summary-table">
              <Summary {...totalIncomes} />
              {overtimeSummaryElements}
              <SummaryTotal {...totalAmount} />
            </div>
          </CardContent>
        </Card>
      </div>
      <button
        class="btn btn--primary download-summary"
        onClick={() => printDocument()}
      >
        <FormattedMessage id={"label.download_summary"} />
      </button>
    </div>
  );
}

export default SummaryList;
