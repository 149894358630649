import React, { useContext } from 'react'
import { FormattedMessage } from "react-intl"
import { Card, CardContent } from './../utils/Card'
import { CompareFormContext } from './index'

export default function AmountInput(props) {
	const { handleAmountChange } = useContext(CompareFormContext)
	
	const {
		currency,
		amount
	} = props
	
	function handleChange(amount) {
		handleAmountChange(parseInt(amount))
	}

	return (
		<Card className={"card--white"}>
        	<CardContent>
				<div className="form-group">
					<label className="label-control" htmlFor="amount">
						<FormattedMessage id="label.how_much" />
					</label>
					<div className="input-group">
		                <span className="input-currency">{currency}</span>
		                <input
		                    className="form-control"
		                    id="amount"
		                    name="amount"
		                    type="number"
							min="0"
							step="1"
							value={amount}
							onChange={e => handleChange(e.target.value)}
		                />
		            </div>
				</div>
			</CardContent>
		</Card>
	)
}