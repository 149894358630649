import React, { useContext } from 'react'
import { Card, CardHeader, CardContent } from '../utils/Card';
import PayPeriodSelect from '../form/PayPeriodSelect';
import CurrencySelect from './CurrencySelect';
import { FormattedMessage } from "react-intl";
import { BudgetContext } from './index'

export default function PrimaryIncome() {
	const { handleIncomeChange, primaryIncome } = useContext(BudgetContext)

	const {
		id,
		name,
		amount,
		pay_period
	} = primaryIncome

	function handleChange(name, value) {
		let changes = { [name]: parseFloat(value) }
		handleIncomeChange(id, { ...primaryIncome, ...changes })
	}

	return (
		<React.Fragment>
			<Card className={"card--white"}>
				<CardContent>
					<CardHeader>
						<h3><FormattedMessage id="label.primary_income" /></h3>
					</CardHeader>
					<div className="form-row">
						<input
							className="form-control"
							id="name"
							name="name"
							type="hidden"
							value={name}
						/>
						<div className="form-group">
							<label className="label-control" htmlFor="amount">
								<FormattedMessage id="label.amount" />
							</label>
							<div className="input-group">
								<CurrencySelect type="select--currency" />
								<input
									className="form-control"
									id="amount"
									name="amount"
									type="number"
									min="0"
									step="0.05"
									value={amount}
									onChange={e => handleChange('amount', e.target.value)}
								/>
							</div>
						</div>
						<div className="form-group">
							<label className="label-control" htmlFor="basic-wage">
								<FormattedMessage id="label.pay_period" />
							</label>
							<PayPeriodSelect
								name="pay_period"
								value={pay_period}
								onChange={handleChange}
							/>
						</div>
					</div>
				</CardContent>
			</Card>
		</React.Fragment>
	)
}