import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const items = [{
    id: 'menu.about',
    path: '/page/about'
}, {
    id: 'menu.privacy_policy',
    path: '/page/privacy-policy'
}, {
    id: 'menu.terms_and_conditions',
    path: '/page/terms-and-conditions'
}, {
    id: 'menu.disclaimer',
    path: '/page/disclaimer'
}]

export default function Menu({ isOpen }) {
	const classIsOpen = isOpen === true ? " is-open" : ""

	return (
		<React.Fragment>
			<ul className={classIsOpen}>
				{items.map((item, index) => (
					<li key={index}>
						<Link to={item.path}><FormattedMessage id={item.id} /></Link>
					</li>
				))}
			</ul>
		</React.Fragment>
	);
}