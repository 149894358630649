import React from 'react';
import { FormattedMessage } from "react-intl";
import Icon from './../utils/Icon';

export default function BottomPagination(props) {
    const {
        currentStep,
        totalSteps,
        handleCurrentStep
    } = props

    function previousButton() {
        if (currentStep !== 1) {
            return (
		        <button className="previous" type="button" onClick={() => handleCurrentStep(-1)}>
                    <Icon icon={"arrow_left"} width={20} height={20} /> <FormattedMessage id="button.back" />
				</button>
            )
        }

        return null;
    }

    function nextButton() {
        if (currentStep !== totalSteps) {
            return (
                <button className="next" type="button" onClick={() => handleCurrentStep(1)}>
                    <FormattedMessage id="button.next" /> <Icon icon={"arrow_right"} width={20} height={20} />
                </button>
            )
        }

        return null;
    }

    return (
    	<nav className="bottom-pagination">
            <div className="container">
                {previousButton()}
                {nextButton()}
            </div>
        </nav>
    );
}
