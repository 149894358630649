import React from 'react'
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

function SummaryList({ list }) {
    const data = list.map(item => {
		return { value: item.amount }
    })
    
    return (
        <div className="card-chart">
            <ResponsiveContainer>
                <PieChart width={250} height={250}>
                    <Pie data={data}
                        dataKey="value"
                        cy={130}
                        startAngle={180}
                        endAngle={0}
                        innerRadius={115}
                        outerRadius={130}
                        paddingAngle={5}>
                        {list.map(item => {
                            return <Cell key={item.label} fill={item.color} />
                        })}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    )
}

export default SummaryList;
