import React, { useContext } from 'react'
import Overtime from './Overtime';
import { OvertimeContext } from './index'

export default function OvertimeList() {
	const { overtime } = useContext(OvertimeContext)

	const incomeElements = overtime.map(item => {
		return <Overtime key={item.id} {...item} />
	})

	return (
		<React.Fragment>
      		{incomeElements}
        </React.Fragment>
	)
}
