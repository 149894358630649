import React from "react";
import Icon from "./../utils/Icon";
import FilterIcon from "./FilterIcon";
import { Link } from "react-router-dom";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";

export default function Operator(props) {
  const {
    CompanyName,
    CompanyLink,
    ReferralLink,
    CompanyLogo,
    CurrencyTo,
    CurrencyFrom,
    CryptoCurrency,
    TypePath,
    TypeDesc,
    TypeDeliveryFromPath,
    TypeDeliveryFromDesc,
    TypeDeliveryToPath,
    TypeDeliveryToDesc,
    TimeDeliveryPath,
    TimeDeliveryDesc,
    AmountTo,
    AmountFrom,
    Fee,
    FeeCurrency,
    AdditionalFee,
    Rate,
    Live,
    DataLayer,
  } = props;

  const TypeDeliveryFromElements = TypeDeliveryFromPath.map((path, key) => {
    return <FilterIcon path={path} key={key} />;
  });

  const TypeDeliveryToElements = TypeDeliveryToPath.map((path, key) => {
    return <FilterIcon path={path} key={key} />;
  });

  function referralLinkClick() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(DataLayer);
  }

  const [isOpened, setIsOpened] = React.useState(false);

  function toggleInfo() {
    setIsOpened((wasOpened) => !wasOpened);
  }

  const isOpenedClass = "more-info-btn" + (isOpened ? " open" : "");

  let TypeElements;

  if (TypePath.length > 0) {
    TypeElements = TypePath.map((path, key) => {
      return <FilterIcon path={path} key={key} />;
    });
  }

  let External = CompanyLink != ReferralLink ? true : false;

  function getReferralLinkUrl() {
    let l = ReferralLink;
    l = l.replace(/{{AmountTo}}/g, AmountTo);
    l = l.replace(/{{AmountFrom}}/g, AmountFrom);
    l = l.replace(/{{CurrencyTo}}/g, CurrencyTo);
    l = l.replace(/{{CurrencyFrom}}/g, CurrencyFrom);
    l = l.replace(/{{CurrencyCrypto}}/g, CryptoCurrency);
    return l;
  }

  let CompanyLinkForApp = CompanyLink.substring(
    CompanyLink.indexOf("/compare")
  );

  const hasAdditionalFee = AdditionalFee == 1;

  const hasFee = Fee >= 0.01;

  const CompanyLive =
    Live == 1 ? "live" : Live == 2 ? "fortnightly" : "offline";

  const CompanyImage = CompanyLogo ? (
    <img
      src={CompanyLogo}
      title={CompanyName}
      alt={CompanyName}
      className="operator-logo-img"
    />
  ) : (
    CompanyName
  );

  return (
    <tr>
      <td className={CompanyLive}>
        <div className="container">
          <div className="row mto">
            <div className="col-xs-12 col-sm-3 transfer-operator">
              <div className="operator-logo">
                <Link to={CompanyLinkForApp} className="operator-details">
                  {CompanyLogo ? CompanyImage : CompanyName}
                </Link>
              </div>
              <div className="operator-info">
                <Link to={CompanyLinkForApp}>
                  <Icon icon={"information"} />
                </Link>
              </div>
            </div>

            <div className="col-xs-6 col-sm-3 transfer-details">
              <div className="type-icon">
                <FilterIcon path={TimeDeliveryPath} />
              </div>

              <div className="type-icon">
                {TypeDeliveryFromElements}
                {TypeDeliveryToElements && (
                  <>
                    <Icon icon={"arrow_right"} />
                    {TypeDeliveryToElements}
                  </>
                )}
              </div>
            </div>

            <div className="col-xs-6 col-sm-4 transfer-cost">
              <h1>
                <span className="note">
                  <IntlProvider locale="en">
                    <FormattedNumber
                      value={AmountFrom}
                      style="currency"
                      currency={CurrencyFrom}
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                  </IntlProvider>
                  {" ="}
                </span>
                <br />
                <span>
                  <IntlProvider locale="en">
                    <FormattedNumber
                      value={AmountTo}
                      style="currency"
                      currency={CurrencyTo}
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                  </IntlProvider>
                </span>
                {hasAdditionalFee && <span className="note-primary"> *</span>}
              </h1>
              <div className="transfer-charges">
                <div>
                  <span>
                    <FormattedMessage id="label.fx" />{" "}
                    <FormattedNumber
                      value={Rate}
                      style={"decimal"}
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                  </span>
                  {hasFee && (
                    <span>
                      {" + "}
                      <IntlProvider locale="en">
                        <FormattedNumber
                          value={Fee}
                          style="currency"
                          currency={FeeCurrency}
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      </IntlProvider>{" "}
                      <FormattedMessage id="label.fee" />
                    </span>
                  )}
                </div>
                {hasAdditionalFee && (
                  <div className="note-primary text-xs font-bold padding-top-xxxs">
                    <FormattedMessage id="label.additional_fee" />
                  </div>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-2 transfer-buttons">
              {External && (
                <div className="referral-btn">
                  <a
                    href={getReferralLinkUrl()}
                    className="btn btn--primary referral-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => referralLinkClick()}
                  >
                    <FormattedMessage id="button.send_money" />
                    <Icon icon={"send_money"} />
                  </a>
                </div>
              )}
              <div className={isOpenedClass}>
                <a
                  className="btn btn--primary--outline btn--icon more-info"
                  onClick={() => toggleInfo()}
                >
                  <span>
                    <FormattedMessage id="button.more_details" />
                    <Icon icon={"arrow_down"} />
                  </span>
                </a>
              </div>
            </div>
          </div>

          {isOpened && (
            <div className="row transfer-additional-info">
              <div className="col-xs-12 col-sm-6 info">
                <div className="info-logo">
                  <FilterIcon path={TimeDeliveryPath} />
                </div>
                <div>
                  <h6>
                    <FormattedMessage id="label.speed" />
                  </h6>
                  <div className="desc">{TimeDeliveryDesc}</div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 info">
                <div className="info-logo">
                  <Icon icon={"fee"} />
                </div>
                <div>
                  <h6>
                    <FormattedMessage id="label.fee" />
                  </h6>
                  <div className="desc">
                    {hasFee && (
                      <IntlProvider locale="en">
                        <FormattedNumber
                          value={Fee}
                          style="currency"
                          currency={FeeCurrency}
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      </IntlProvider>
                    )}
                    {!hasFee && (
                      <FormattedMessage id="label.no_additional_fees" />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 info">
                <div className="info-logo">{TypeDeliveryFromElements}</div>
                <div>
                  <h6>
                    <FormattedMessage id="label.send_method" />
                  </h6>
                  <div className="desc">{TypeDeliveryFromDesc.join(" ")}</div>
                </div>
              </div>

              <div className="col-xs-12 col-sm-6 info">
                <div className="info-logo">{TypeDeliveryToElements}</div>
                <div>
                  <h6>
                    <FormattedMessage id="label.receive_method" />
                  </h6>
                  <div className="desc">{TypeDeliveryToDesc.join(" ")}</div>
                </div>
              </div>

              <div className="col-xs-12 col-sm-6 info">
                <div className="info-logo">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="svg-icon"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                  </svg>
                </div>
                <div>
                  <h6>
                    <FormattedMessage id="label.rate" />
                  </h6>
                  <div className="desc">
                    <FormattedNumber
                      value={Rate}
                      style={"decimal"}
                      minimumFractionDigits={4}
                      maximumFractionDigits={4}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 info">
                <div className="info-logo">
                  <FilterIcon path={TypePath} />
                </div>
                <div>
                  <h6>
                    <FormattedMessage id="label.form" />
                  </h6>
                  <div className="desc">{TypeDesc.join(" ")}</div>
                </div>
              </div>
              <div className="col-xs-12 start-xs center-sm info">
                <Link to={CompanyLinkForApp} className="more--info--link">
                  <div className="info-logo">
                    <Icon icon={"information"} />
                  </div>
                  <div>
                    <FormattedMessage id="label.more_options_available" />
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
}
