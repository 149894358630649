import React, { useContext } from 'react'
import { FormattedMessage } from "react-intl"
import { Card, CardContent } from './../utils/Card'
import CompareSelect from './CompareSelect'
import { CompareFormContext } from './index'

export default function CountryFromSelect() {
	const {
		countryOptions,
		countryFrom,
		handleCountryFromChange
	} = useContext(CompareFormContext)

	return (
		<Card className={"card--white"}>
        	{countryOptions && <CardContent>
				<div className="form-group">
					<label className="label-control" htmlFor="country_from">
						<FormattedMessage id="label.country_from" />
					</label>
					{countryFrom && <CompareSelect
											name="country_from"
											current={countryFrom}
											options={countryOptions}
											handleChange={handleCountryFromChange}
										/>}
				</div>
			</CardContent>}
		</Card>
	)
}