import React, { useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import Icon from "./../utils/Icon";
import FilterIcon from "./FilterIcon";
import { CompareListContext } from "./CompareList";

export default function Filter(props) {
  const { name, type, list, fields } = props;

  const { handleFilteredAdd, handleFilteredRemove } =
    useContext(CompareListContext);

  const [selectedFilter, setSelectedFilter] = useState();
  const [stateFilter, setStateFilter] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const classIsOpen = isOpen === true ? "is-open" : "";

  const listElements = list.map((item) => {
    return (
      <li
        key={item.label}
        onClick={() => handleFilterOnClick(item)}
        className={
          stateFilter.label === item.label
            ? `type-icon filter-active`
            : `type-icon `
        }
        data-for={item.slug}
      >
        {item.path && <FilterIcon path={item.path} />}
        {item.label}
        {stateFilter.label === item.label && <Icon icon={"check"} />}
      </li>
    );
  });

  function handleFilterOnClose() {
    setIsOpen(false);
    !selectedFilter && setStateFilter("");
    !stateFilter && setSelectedFilter("");
  }

  function handleFilterOnClick(filter) {
    stateFilter.label === filter.label
      ? setStateFilter("")
      : setStateFilter(filter);
  }

  function handleApplyOnClick() {
    setIsOpen(false);
    setSelectedFilter(stateFilter);

    if (stateFilter.value) {
      var s = list.filter(function (f) {
        return f.value == stateFilter.value;
      }, stateFilter);
      const newFilter = {
        name: name,
        type: type,
        value: s[0].value,
        sortby: s[0].sortby,
        order: s[0].order,
        fields: fields,
      };

      handleFilteredAdd(newFilter);
    } else {
      handleFilteredRemove(name);
    }
  }

  function handleClearOnClick() {
    setIsOpen(false);

    if (stateFilter.value) {
      handleFilteredRemove(name);
    }

    setStateFilter("");
    setSelectedFilter("");
  }

  return (
    <React.Fragment>
      <button className="filter-control" onClick={() => setIsOpen(true)}>
        <span className="text">
          {selectedFilter ? selectedFilter.label : name}
        </span>
        <Icon icon={"add"} />
      </button>
      <div className={`select-modal ${classIsOpen}`}>
        <div className="select-modal--content">
          <ul className="select-modal--list">
            <li>
              <h2>{name}</h2>
              <button onClick={() => handleFilterOnClose()}>
                <Icon icon={"clear"} />
              </button>
            </li>
            {listElements}
          </ul>
          <div className="select-modal--action">
            <button
              type="button"
              className="btn btn--primary"
              onClick={() => handleApplyOnClick()}
            >
              <FormattedMessage id="button.apply" />
            </button>
          </div>
          <div className="select-modal--action">
            <button
              type="button"
              className="btn"
              onClick={() => handleClearOnClick()}
            >
              <FormattedMessage id="button.clear" />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
