import React, { useState } from 'react';
import { Card, CardContent, CardAction } from './Card';
import { FormattedMessage } from "react-intl";

export default function Accordion({ title, excerpt, body }) {
    const [isOpen, setIsOpen] = useState(false)

    function openAccordion(e) {
    	e.preventDefault()

        setIsOpen(isOpen === true ? false : true)
    }

    const classIsOpen = isOpen === true ? " is-open" : ""

    const showButton = excerpt.length === body.length ? false : true

    return (
        <Card className={"card--white card--accordion" + classIsOpen}>
            <CardContent>
                <h4>{title}</h4>
                <div
                    className="card--accordion-excerpt"
                    dangerouslySetInnerHTML={ {__html: excerpt} }
                />
                {showButton && <div
                    className="card--accordion-body"
                    dangerouslySetInnerHTML={ {__html: body} }
                />}
            </CardContent>
            {showButton && <CardAction>
                <a href="#!" onClick={openAccordion}>
                    {!isOpen && <FormattedMessage id="button.read_more" />}
                    {isOpen && <FormattedMessage id="button.show_less" />}
                </a>
            </CardAction>}
        </Card>
    );
}
