import React, { useState } from 'react'
import Icon from './../utils/Icon';
import { injectIntl } from "react-intl";

function PayPeriodSelect(props) {
    const {
        name,
        value,
        onChange,
        intl
    } = props

    const payPeriods = [{
        value: 1,
        label: intl.formatMessage({id: "label.daily"})
    }, {
        value: 7,
        label: intl.formatMessage({id: "label.weekly"})
    }, {
        value: 30,
        label: intl.formatMessage({id: "label.monthly"})
    }]

    const initialIndex = payPeriods.findIndex(o => o.value === value)

    const [payPeriod, setPayPeriod] = useState(payPeriods[initialIndex])

    const {
        label
    } = payPeriod

    const elements = payPeriods.map(option => {
        return <option key={option.value} value={option.value}>{option.label}</option>
    })

    function handleSelectOnChange(changed) {
        const index = payPeriods.findIndex(o => o.value === parseInt(changed))
        setPayPeriod(payPeriods[index])
        onChange('pay_period', payPeriods[index].value)
    }

    return (
        <div className="select-control">
            <select
                name={name}
                value={value}
                onChange={(e) => handleSelectOnChange(e.target.value)}
            >
                {elements}
            </select>
            <span className="text">
                {label}
            </span>
	    <Icon icon={"arrow_down"} />
        </div>
    )
}

const WrappedPayPeriodSelect = injectIntl(PayPeriodSelect)

export default WrappedPayPeriodSelect;
